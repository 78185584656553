import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1rem 0",

  "& .avatarContainer": {
    display: "flex",
    alignItems: "center",
    gap: "0 0.5rem",

    "& .textSection": {
      "& .name": {
        color: "#150200",
        fontSize: "1.125rem",
        fontWeight: 600,
      },
    },
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    "& .logo": {
      width: "40px",
    },

    "& .avatarContainer": {
      "& img": {
        width: "25px",
        height: "25px",
      },

      "& .textSection": {
        "& .name": {
          fontSize: "0.875rem",
        },
      },
    },
  },
}));
