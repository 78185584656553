import * as React from "react";
import Lottie from "lottie-react";
import EmptyStateLottie from "../../assets/lottie/emptyState.json";
import { PyButton } from "../button/button";

export const EmptyState: React.FC<IProps> = ({
  clickFunc,
  btnText,
  emptyText = "",
  width = "w-[270px]",
  height = "h-[270px]",
}) => {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <Lottie
        className={`mx-auto d-block mb-1 ${width} ${height}`}
        animationData={EmptyStateLottie}
        loop={true}
      />

      <div className="text-py_black-400 text-sm font-normal mb-8 lg:text-base">
        {emptyText ? emptyText : "Nothing to see here yet"}
      </div>
      {btnText && clickFunc && (
        <PyButton
          extraClass="!border w-fit !mx-auto"
          variant="Secondary"
          click={clickFunc}>
          {btnText}
        </PyButton>
      )}
    </div>
  );
};

interface IProps {
  emptyText?: string;
  clickFunc?: () => void;
  btnText?: string | React.ReactNode;
  width?: string
  height?: string
}
