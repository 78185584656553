import React from "react"
import { NavBar } from "./NavBar"
import { Footer } from "./Footer";
import { Outlet } from "react-router-dom";

interface IProps {
    children?: React.ReactNode;
}

export const AppBaseLayout: React.FC<IProps> = ({
    children
}) => {
    return (
        <React.Fragment>
            <NavBar />
            <section className="w-full min-h-[calc(100vh-227px)] lg:min-h-[calc(100vh-275px)]">
                {children}
                <Outlet />
            </section>
            <Footer />
        </React.Fragment>
    )
}