import toastr from "toastr"
import "toastr/build/toastr.css"

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: () => true,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 500,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
}

export const ToastMessage = (header: TOASTER_TYPE, message: string) => {
  let type: ToastrType

  switch (header) {
    case "SUCCESS":
      type = "success"
      break

    case "INFO":
      type = "info"
      break

    case "ERROR":
      type = "error"
      break

    case "WARN":
      type = "warning"
      break

    default:
      type = "info"
      break
  }

  toastr[type](message)
}

export enum TOASTER_TYPE {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  ERROR = "ERROR",
  WARN = "WARN",
}
