import * as React from "react";
import MainROutes from "./routes/main-routes";
import { MsalProvider } from "@azure/msal-react";
import { ClientSideNavigation } from "./routes/client-navgation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getSessionData } from "./config";
import { HelmetProvider } from "react-helmet-async";

const App: React.FC<any> = ({ pca }) => {
  const location = useLocation();
  const [isQueryString, setIsQueryString] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Access query string parameters from location.search
    const queryParams = new URLSearchParams(location.search);
    const dataParam = queryParams.get("data");

    const sessionData = getSessionData();

    if (dataParam) {
      setIsQueryString(true);
      // Decode the data parameter if needed
      const decodedData = decodeURIComponent(dataParam);

      // Parse the JSON string into an object
      const sessionData = JSON.parse(decodedData);

      // Now you have the sessionData object
      // console.log("Received session data:", sessionData);

      // Do further processing with sessionData as needed
      localStorage.setItem("session_data", JSON.stringify(sessionData));
      // Remove the query parameter from the URL
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    } else if (sessionData) {
      setIsQueryString(true);
      return;
    } else {
      setIsQueryString(false);
    }
  }, [location.search]);

  const queryClient = new QueryClient(); 

  // const helmetContext = { prioritizeSeoTags: true };

  return (
    <>
      <HelmetProvider>
        <ClientSideNavigation pca={pca}>
          <MsalProvider instance={pca}>
            <QueryClientProvider client={queryClient}>
              <main className="app_wrapper">
                <MainROutes />
              </main>
            </QueryClientProvider>
          </MsalProvider>
        </ClientSideNavigation>
      </HelmetProvider>
    </>
  );
};

export default App;
