import * as React from "react";
import { styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { FooterBox } from "./footer.styles";
import { SecureIcon } from "../../../assets/svg";
import PaycurraLogo from '../../../assets/logo/logo-paycurra.svg'
import GooglePlayImg from "../../../assets/images/black-google-play.svg"
import AppleStoreImg from "../../../assets/images/black-apple-store.svg"

export const Footer = () => {
  const { accounts } = useMsal();

  return (
    <>
      <FooterBox>
        {accounts && accounts.length >= 1 ? (
          <>
            <div className="textHolder">
              <div className="mb-0 d-flex gap-4">
                <div className="d-inline-flex gap-1 align-items-center secured">
                  <SecureIcon />
                  SECURED BY
                </div>
                <Link to="/" className="d-inline-flex flex-column logo-link">
                  <img src={PaycurraLogo} alt="Paycurra logo" />
                  <small className="ms-auto">Checkout</small>
                </Link>
              </div>
            </div>

            <div className="text-center copy">
              &copy; {new Date().getFullYear()}
            </div>
          </>
        ) : (
          <div className="py-10 text-center">
            <h4 className="text-[1.25rem] text-py_black-700 font-medium	">
              Download mobile app
            </h4>
            <p className="text-[0.875rem] text-py_black-500 font-normal">
              Content on why to download app goes in here....
            </p>

            <div className="flex !gap-2 items-center justify-center">
              <img src={GooglePlayImg} alt="Download Paycurra on Google play" />
              <img src={AppleStoreImg} alt="Download Paycurra on App store" />
            </div>
          </div>
        )}
      </FooterBox>
    </>
  );
};
