import { styled } from "@mui/material";
import BubbleCheckImg from "../../assets/images/speech-bubble.svg";
import HappyLoggedInImg from "../../assets/images/happy-login.svg";
import CheckedLoggedInImg from "../../assets/images/checked-loggedin-img.svg";
import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../config";
import { initializeOpenSession } from "../../services/api";
import { decodeToken } from "../../utils/helper";

export const LoginSuccessBox = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  padding: "3rem 0",
  height: "100vh",
  background: "#ffffff",

  "& .imgContainer": {
    height: "280px",
    width: "280px",
    margin: "0 auto",
    // background: `url(${HappyLoggedInImg})`,
    // backgroundPosition: "center",
    // backgroundSize: "cover",
  },

  "& .successText": {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.65rem",
    color: "#00AE97",
  },

  "& .details": {
    color: "#847F7F",
    fontSize: "1rem",
    fontWeight: 400,
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {},
}));

export const LoginSuccessResponse = () => {
  const { instance, accounts } = useMsal();
  const [decodedToken, setDecodedToken] = React.useState(null);
  const [isSessionOpen, setIsSessionOpen] = React.useState(false);

  React.useEffect(() => {
    // if (accounts && accounts.length >= 1) {
    //   if (!decodedToken) acquireToken(accounts[0]);
    // }
  }, [accounts]);

  // acquire token
  const acquireToken = async (account: any) => {
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account: account,
      }as any);

      if (response) {
        const decodedToken = decodeToken(response.accessToken);
        setDecodedToken(decodedToken);
        if (!isSessionOpen) handleOpenSession(decodedToken.sub);
      }
    } catch (err) {}
  };

  // Open Session
  const handleOpenSession = async (consumerURN: string) => {
    const payload = {
      consumerUniqueReferenceNumber: consumerURN,
    };

    await initializeOpenSession(payload).then((res: any) => {
      console.log(res, "Session Opened!");
      setIsSessionOpen(true);
    });
  };

  return (
    <>
      {isSessionOpen && decodedToken && (
        <LoginSuccessBox>
          <div className="imgContainer">
            <img
              src={CheckedLoggedInImg}
              className="img-fluid d-block mx-auto"
            />
          </div>
          <div className="successText">Login Successful!</div>
          <div className="details">You can now apply rewards and earn more</div>
        </LoginSuccessBox>
      )}
    </>
  );
};
