import { styled } from "@mui/material";

export const StripeStylesBox = styled("div")(({ theme }) => ({
  "& .headerSection": {
    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.10)",
    padding: "1.5rem",

    "& .title": {
      color: "#150200",
      fontFamily: "Poppins",
      fontSize: "1.25rem",
      fontWeight: 600,
      marginBottom: 0,
    },
  },

  "& .subSection": {
    padding: "1rem 1.5rem 2rem",
    color: "#847F7F",
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    fontWeight: 400,
    borderBottom: "1px solid #C4C4C4",
  },

  "& .bodySection": {
    padding: "1.5rem 1.5rem 2.5rem",

    "& .logoSection": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "2rem",

      "& .emailHolder": {
        color: "#847F7F",
        fontFamily: "Poppins",
        fontSize: "0.95rem",
        fontWeight: 400,

        "& .amount span": {
          color: "#00AE97",
          fontSize: "1.15rem",
          fontWeight: 700,
        },
      },
    },

    "& .creditSection": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: "2rem",
      alignItems: "baseline",

      "& .textHolder": {
        color: "#150200",
        fontWeight: 400,
        fontSize: "1rem",
      },
    },

    "& .creditCardSection": {},

    "& .secureNote": {
      margin: "1rem 0 2.5rem",

      "& span": {
        marginLeft: "0.25rem",
        color: "#847F7F",
        fontFamily: "Raleway",
        fontSize: "0.75rem",
        fontWeight: 700,
      },
    },
  },
}));
