import {
  apiHeaders,
  urlConfig,
  getSessionData,
  AxiosInstanceSetUp,
} from "../../config";
import axios from "axios";
import { ToastMessage, TOASTER_TYPE } from "../../components";
import { formatCart } from "../../utils/helper";

const handleError = () => {
  ToastMessage(TOASTER_TYPE.ERROR, "An error occured, Please try again");
};

export const initializeOpenSession: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": payload.name,
        "X-PYC-UserURN": payload.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions`,
      {
        uniqueReferenceNumber: sessionData.uniqueReferenceNumber,
        merchantUniqueReferenceNumber:
          sessionData.merchantUniqueReferenceNumber,
        cart: formatCart(sessionData.cart), // convert cart price to kobo
        consumerUniqueReferenceNumber: payload.conURN,
        walletUniqueReferenceNumber: payload.wURN,
        shipping: sessionData.shipping,
        bookings: sessionData.bookings,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getSessionByUrn: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const axiosInstance = AxiosInstanceSetUp();

    const response = await axiosInstance({
      url: `/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}`,
      method: "GET",
    });

    return response;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const updateOpenSession: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.put(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}`,
      {
        consumerUniqueReferenceNumber: payload.conURN,
        walletUniqueReferenceNumber: payload.wURN,
        merchantUniqueReferenceNumber:
          sessionData.merchantUniqueReferenceNumber,
        cart: formatCart(sessionData.cart), // Update cart for an existing session
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const updateStoreCartItem: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const axiosInstance = AxiosInstanceSetUp();

    const response = await axiosInstance({
      url: `/campaigns/${sessionData.tenant}/v1/StoreCartItems/${payload.uniqueReferenceNumber}`,
      method: "PATCH",
      data: payload,
    });

    return response;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const discardStoreCartItem: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const axiosInstance = AxiosInstanceSetUp();

    const response = await axiosInstance({
      url: `/campaigns/${sessionData.tenant}/v1/StoreCartItems/${sessionData.uniqueReferenceNumber}`,
      method: "DELETE",
      data: {
        ...payload,
        consumerUniqueReferenceNumber: sessionData.conURN,
        merchantUniqueReferenceNumber:
          sessionData.merchantUniqueReferenceNumber,
      },
    });

    return response;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

// enroll for loyalty
export const enrollForLoyalty: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const axiosInstance = AxiosInstanceSetUp();

    const response = await axiosInstance({
      url: `/wallets/${sessionData.tenant}/v1/Points`,
      method: "POST",
      data: payload,
    });

    return response;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getAllEnrolledLoyalty: any = async (data: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const params = new URLSearchParams({
      CustomerUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      MerchantUniqueReferenceNumber: sessionData.tenant,
      ConsumerUniqueReferenceNumber: sessionData.conURN,
      State: data.state,
    });

    const axiosInstance = AxiosInstanceSetUp();

    const response = await axiosInstance({
      url: `/loyalties/${sessionData.tenant}/v1/Members?${params}`,
      method: "GET",
    });

    return response;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getAllRewards: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const paramData: any = {
      WalletUniqueReferenceNumber: sessionData.wURN,
      ConsumerUniqueReferenceNumber: sessionData.conURN,
      // ExpiryQuery: "NonExpired",
      PageSize: 50,
    };

    const params = new URLSearchParams();

    for (const key in paramData) {
      if (paramData.hasOwnProperty(key)) {
        params.set(key, paramData[key].toString());
      }
    }

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Rewards?${params}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getCartItemsInSession: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: apiHeaders,
    };

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

// Transaction and Payment
export const initializeTransaction: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();
    const tenant = payload.customerUniqueReferenceNumber || sessionData.tenant

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData?.name,
        "X-PYC-UserURN": sessionData?.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/payments/${tenant}/v1/Transactions`,
      {
        ...payload,
        paymentItemUniqueReferenceNumber: payload.paymentItemUniqueReferenceNumber || sessionData.uniqueReferenceNumber,
        customerUniqueReferenceNumber: payload.customerUniqueReferenceNumber || sessionData.tenant,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const verifyTransaction: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();
    const tenant = sessionData.tenant || payload.customerUniqueReferenceNumber

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/payments/${tenant}/v1/Transactions/${sessionData.uniqueReferenceNumber || payload.uniqueReferenceNumber}/verifications`,
      {
        ...payload,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getMerchantDetails = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const axiosInstance = AxiosInstanceSetUp();

    const response = await axiosInstance({
      url: `Subscriptions/${urlConfig.API_TENANT}/v1/Customers/${sessionData.merchantUniqueReferenceNumber}`,
      method: "GET",
    });

    return response.data;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getPaymentGateway: any = async (data: any) => {
  let sessionData: any;

  const headerDetails = data.queryKey[1];

  try {
    sessionData = getSessionData();
    const token = localStorage.getItem("ID_T");

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name || headerDetails.name,
        "X-PYC-UserURN": sessionData.conURN || headerDetails.conURN,
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${urlConfig.BASE_URL}/payments/${sessionData.tenant}/v1/GatewaySettings`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const createCartToken: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens`,
      {
        ...payload,
        consumerUniqueReferenceNumber: sessionData.conURN,
        walletUniqueReferenceNumber:
          sessionData.wURN || payload.walletUniqueReferenceNumber,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const selectDefaultCardToken: any = async (payload: any) => {
  const { uniqueReferenceNumber, ...data } = payload;
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.put(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${uniqueReferenceNumber}/defaults`,
      data,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const replaceCardToken: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.put(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${payload.uniqueReferenceNumber}`,
      {
        ...payload,
        consumerUniqueReferenceNumber: sessionData.conURN,
        walletUniqueReferenceNumber: sessionData.wURN,
        merchantUniqueReferenceNumber:
          sessionData.merchantUniqueReferenceNumber,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const removeCardToken: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const paramData = {
      WalletUniqueReferenceNumber: sessionData.wURN,
      GatewayCustomerId: payload.GatewayCustomerId,
    };

    const params = new URLSearchParams({
      ...paramData,
    });

    const res = await axios.delete(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${payload.uniqueReferenceNumber}/?${params}&CustomerEmailAddress=${payload.CustomerEmailAddress}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getCardToken: any = async (data: any) => {
  let sessionData: any;
  const tenant = data.cusUrn || sessionData.tenant;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData?.name || data?.name,
        "X-PYC-UserURN": sessionData?.conURN || data?.conUrn,
      },
    };

    const paramData = {
      CustomerUniqueReferenceNumber: data.cusUrn || sessionData.tenant,
      MerchantUniqueReferenceNumber: data.cusUrn || sessionData.merchantUniqueReferenceNumber,
      ConsumerUniqueReferenceNumber: data.conUrn || sessionData.conURN,
      WalletUniqueReferenceNumber: data.wUrn,
    };

    const params = new URLSearchParams({
      ...paramData,
    });

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${tenant}/v1/CardTokens/?${params}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getCardTokenWithUrn: any = async (data: any) => {
  let sessionData: any;

  const payload = data.queryKey[1];

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name || payload.name,
        "X-PYC-UserURN": sessionData.conURN || payload.conURN,
      },
    };

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${payload.uniqueReferenceNumber}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getEarnableRewards: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const body = {
      merchantUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      consumerUniqueReferenceNumber: sessionData.conURN,
      customerUniqueReferenceNumber: sessionData.tenant,
      walletUniqueReferenceNumber: sessionData.wURN,
      cart: formatCart(sessionData.cart),
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}/rewards`,
      body,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const checkoutToPay: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();
    const token = localStorage.getItem("ID_T");

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      ...payload,
      merchantUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      consumerUniqueReferenceNumber: sessionData.conURN,
      walletUniqueReferenceNumber: sessionData.wURN,
      cart: formatCart(sessionData.cart),
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}/checkouts`,
      body,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getPoints: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    // const groupedByClause = ["loyaltyProgrammeMemberUniqueReferenceNumber", "loyaltyProgrammeTierUniqueReferenceNumber", "LoyaltyProgrammeUniqueReferenceNumber"]

    const paramData = {
      CustomerUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      MerchantUniqueReferenceNumber: sessionData.tenant,
      ConsumerUniqueReferenceNumber: sessionData.conURN,
      GroupByClause: "loyaltyProgrammeMemberUniqueReferenceNumber",
    };

    const params = new URLSearchParams(paramData);

    const res = await axios.get(
      `${urlConfig.BASE_URL}/loyalties/${sessionData.tenant}/v1/AccrualPointAggregation?${params}&GroupByClause=loyaltyProgrammeTierUniqueReferenceNumber&GroupByClause=LoyaltyProgrammeUniqueReferenceNumber`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

// PAYSTACK REQUEST
const PAYSTACK_BASE_URL = "https://api.paystack.co";
const paystackConfig = {
  headers: {
    Authorization: `Bearer ${urlConfig.PAYSTACK_SECRET_KEY}`, // Replace with your actual access token
  },
};

export const getAllBanks = async (data: any) => {
  try {
    const response = await axios.get(
      `${PAYSTACK_BASE_URL}/bank`,
      paystackConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyAccountNumber = async (data: any) => {
  const paramData = {
    account_number: data.accountNumber,
    bank_code: data.bankCode,
  };

  const params = new URLSearchParams({
    ...paramData,
  });

  try {
    const response = await axios.get(
      `${PAYSTACK_BASE_URL}/bank/resolve?${params}`,
      paystackConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

/** Book a demo */
export const bookADemo = async (data: any) => {
  try {
    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": "System",
        "X-PYC-UserURN": "System"
      }
    };

    const body = {
      ...data
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/SubScriptions/${urlConfig.PAYCURRA_ADMIN_URN}/v1/EnterprisePlanRequest`,
      body,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};
