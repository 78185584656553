import { LogLevel } from "@azure/msal-browser";
import { b2cPolicies } from "./policies";
import { urlConfig } from "./url.config";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig = {
  auth: {
    clientId: urlConfig.B2C_CLIENT_ID,
    authority: urlConfig.B2C_AUTHORITY,
    knownAuthorities: urlConfig.B2C_KNOW_AUTHORITIES,
    redirectUri: urlConfig.B2C_REDIRECT_URI,
    postLogoutRedirectUri: urlConfig.B2C_POST_LOGOUT_REDIRECT_URI,
    // navigateToLoginRequestUrl: false,
  },

  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },

  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },

    allowRedirectInIframe: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [urlConfig.B2C_SCOPES],
};

export const tokenRequest = {
  ...loginRequest,
};
