import * as React from "react";
import { FrameBox } from "./CardFram.style";
import { AccordionSummary, AccordionDetails, Checkbox } from "@mui/material";
import { CaretDownIcon } from "../../assets/svg";

interface IFrameProps {
  hasIcon?: boolean;
  icon?: React.SVGProps<SVGSVGElement>;
  title: string;
  subtitle?: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
  hasCheckbox?: boolean;
  handleCheck?: any;
  checkState?: boolean;
  isOrderSummary?: boolean;
}

export const CardFrame: React.FC<IFrameProps> = ({
  hasIcon = false,
  icon,
  title,
  subtitle,
  children,
  hasCheckbox = true,
  handleCheck,
  checkState,
  isOrderSummary = false,
}) => {
  const [expand, setExpand] = React.useState<boolean>(children ? true : false);

  const toggleCardExpand = () => setExpand(!expand);

  return (
    <FrameBox expanded={expand} onChange={toggleCardExpand}>
      <div className="headerSection">
        {hasCheckbox && (
          <Checkbox
            className="checkbox"
            checked={checkState}
            onChange={handleCheck}
            inputProps={{ "aria-label": "controlled" }}
          />
        )}
        {/* {hasCheckbox && <input type={"checkbox"} />} */}
        {hasIcon && <> {icon} </>}

        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="headerContainer">
            <div>
              <div className="titleHolder mb-3">
                <h3 className="title mb-0">{title}</h3>
              </div>
              {subtitle && !isOrderSummary && (
                <p className="details">{subtitle}</p>
              )}
              {subtitle && isOrderSummary && (
                <div className="d-block w-100 mt-1 subTitleAmount">
                  <span>Subtotal</span>
                  <span>{subtitle}</span>
                </div>
              )}
            </div>
          </div>
        </AccordionSummary>
      </div>

      <AccordionDetails
        className={`${!hasIcon ? "detailWithIconMarginLeft" : ""}
        ${!hasCheckbox ? "detailNoMarginLeft" : ""}`}
      >
        <section className="">{children}</section>
      </AccordionDetails>
    </FrameBox>
  );
};
