import * as React from "react";
import { CardDetailsBox } from "./CardDetails.style";
import {
  getCardToken,
  initializeTransaction,
  verifyTransaction,
  createCartToken,
  getPaymentGateway,
  replaceCardToken,
  getCardTokenWithUrn,
  removeCardToken,
} from "../../../../services/api";
import { Dialog } from "@mui/material";
import {
  ConfirmChangeCard,
  AddPaymentMethodSuccess,
  LoadStripe,
  AddPaymentMethod,
} from "./components";
import { Loader } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import { getSessionData } from "../../../../config";
import { ToastMessage, TOASTER_TYPE } from "../../../../components";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RemoveIcon } from "../../../../assets/svg";

interface IProps {
  getCardDetails: any;
  userDetails: any;
}

const paystackCheckoutUrl = "https://checkout.paystack.com"

export const CardDetails: React.FC<IProps> = ({
  getCardDetails,
  userDetails,
}) => {
  const [showCreateMethod, setShowCreateMethod] = React.useState<boolean>(false)
  const [initializeResp, setInitializeResp] = React.useState<any>(null);
  const [openPaystack, setOpenPaystack] = React.useState<boolean>(false);
  const [openStripe, setOpenStripe] = React.useState<boolean>(false);
  const [openAddPaymentMethodSuccess, setOpenAddPaymentMethodSuccess] =
    React.useState<boolean>(false);
  const [openConfirmChange, setOpenConfirmChange] =
    React.useState<boolean>(false);
  const [loadingPayment, setLoadingPayment] = React.useState<boolean>(false);
  const [cardDetails, setCardDetails] = React.useState<any>(getSessionData().paymentMethod);
  const [cardVerificationDetails, setCardVerificationDetails] = React.useState<any>(null);

  const {
    data: gw_data,
    isLoading: gw_loading,
    refetch: gw_refetch,
  } = useQuery<any>(
    [
      "gateWayDetails",
      {
        wURN: userDetails.sub,
        conURN: userDetails.extension_UserURN,
        name: userDetails.name,
      },
    ],
    getPaymentGateway,
    {
      enabled: !!userDetails, // Enable the query when id is truthy
      retry: 0,
      staleTime: Infinity,
    }
  );

  const {
    data: ct_data,
    isLoading: ct_loading,
    refetch: ct_refetch,
  } = useQuery<any>(
    [
      "cardToken",
      {
        wURN: userDetails.sub,
        conURN: userDetails.extension_UserURN,
        name: userDetails.name,
      },
    ],
    getCardToken,
    {
      enabled: !!userDetails, // Enable the query when id is truthy
      retry: 0,
      staleTime: Infinity,
    }
  );

  const onInitializeTransaction = useMutation(initializeTransaction, {
    onSuccess: (res: any, variables) => {
      setOpenConfirmChange(false);
      localStorage.setItem("P_D", JSON.stringify(res.data));
      setInitializeResp(res.data);
      // redirect to paystack checkout 
      window.location.assign(`${paystackCheckoutUrl}/${res.data?.authorizationCode}`)
      ToastMessage(TOASTER_TYPE.SUCCESS, "Redirecting you to payment gateway");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        setLoadingPayment(false);
        setOpenConfirmChange(false);
        // ToastMessage(TOASTER_TYPE.ERROR, error?.response?.statusText);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onVerifyTransaction = useMutation(verifyTransaction, {
    onSuccess: (res: any) => {
      // handleCreateCardToken(res.data);
      // ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log(error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onCreateCartToken = useMutation(createCartToken, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        // console.log(res);
        // if (!res.data.data?.isVerified) {
        // } else {
        //   ToastMessage(TOASTER_TYPE.ERROR, "Oops!, Your name on our system does not match your account name")
        // }
        setCardDetails(res.data.data);
        localStorage.removeItem('VTR')
        localStorage.removeItem('VD')
      }

      ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onRemoveCardToken = useMutation(removeCardToken, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        ct_refetch();

        ToastMessage(TOASTER_TYPE.SUCCESS, "Card token removed successfully");
      }
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        ToastMessage(TOASTER_TYPE.ERROR, "An error occured, please try again");
      }
    },
  });

  const onReplaceCardToken = useMutation(replaceCardToken, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        ToastMessage(TOASTER_TYPE.SUCCESS, "Card token changed successfully");
        // ct_refetch();
      }

      // console.log(res);
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  React.useEffect(() => {
    const _verifyTransactionResp = localStorage.getItem('VTR') && JSON.parse(localStorage.getItem('VTR') as string)
    const _verificationData = localStorage.getItem('VD') && JSON.parse(localStorage.getItem('VD') as string)
    if (_verifyTransactionResp && _verificationData) {
      setCardVerificationDetails(_verificationData)
      handleCreateCardToken(_verifyTransactionResp, _verificationData)
    }
  }, []);

  React.useEffect(() => {
    if (userDetails) {
      gw_refetch();
      // ct_refetch();
    }
  }, [userDetails]);

  React.useEffect(() => {
    if ((ct_data && ct_data?.data) || (gw_data && gw_data?.data)) {
      ct_data?.data?.data.forEach((card_token: any) => {
        if (
          card_token &&
          card_token?.statuses &&
          card_token?.statuses[card_token?.statuses.length - 1]?.statusName ===
          "Active"
        ) {
          setCardDetails(card_token);
        } else {
          setCardDetails(null);
        }
      });

      // getCardDetails({ card: cardDetails, gateway: gw_data?.data });
    }
  }, [ct_data, gw_data]);

  React.useEffect(() => {
    if (cardDetails || (gw_data && gw_data?.data)) {
      getCardDetails({ card: cardDetails, gateway: getLiveGatewayDetails(gw_data?.data) });
    }
  }, [cardDetails, gw_data]);

  const getLiveGatewayDetails = (allGateway: any[]) => {
    if (!allGateway || allGateway.length < 1) return
    return allGateway.find((item) => item.environment === "Live")
  }

  const handleAddPaystackCard = async () => {
    setLoadingPayment(true);

    const body = {
      paymentUniqueReferenceNumber: uuidv4(),
      amount: 10000, // In kobo
      customerEmail: userDetails.email,
      customerId: "null",
      paymentCurrency: "NGN",
      paymentMethod: "card",
    };

    onInitializeTransaction.mutate(body as any);
  };

  const handleCreateCardToken = async (verificationData: any, _cardVerificationDetails: any) => {
    const body = {
      merchantUniqueReferenceNumber: verificationData.customerUniqueReferenceNumber,
      customerUniqueReferenceNumber: verificationData.customerUniqueReferenceNumber,
      paymentGatewayReference: verificationData.paymentGatewayReferenceNumber,
      customerEmailAddress: userDetails.email,
      // gatewayCustomerId: verificationData.gatewayCustomerId ? verificationData.gatewayCustomerId : "none",
      newCustomer: true,
      verification: {
        ..._cardVerificationDetails,
        firstName: userDetails?.given_name,
        lastName: userDetails?.family_name,
        verificationRequired: false,
      }
    };

    onCreateCartToken.mutate(body as any);
  };

  const handleStripePayment = () => {
    setOpenStripe(true);
    setOpenConfirmChange(false);
  };

  const handleAddCardSelection = () => {
    if (!gateWayDetails) {
      ToastMessage(TOASTER_TYPE.ERROR, "No gateway setting");
      return;
    }

    if (gateWayDetails.paymentGatewayType.toLowerCase() === "stripe") {
      handleStripePayment();
    }

    if (gateWayDetails.paymentGatewayType.toLowerCase() === "paystack") {
      handleAddPaystackCard();
    }
  };

  const handleRemoveCard = () => {
    onRemoveCardToken.mutate({
      GatewayCustomerId: cardDetails?.gatewayCustomerId,
      CustomerEmailAddress: cardDetails?.emailAddress,
      PaymentMethodUniqueReferenceNumber: cardDetails?.paymentMethodUniqueReferenceNumber || "string",
      uniqueReferenceNumber: cardDetails?.uniqueReferenceNumber,
    } as any);
  };

  const handleVerificationDetails = (verificationData: any) => {
    // Verification Data
    localStorage.setItem("VD", JSON.stringify(verificationData))
    setCardVerificationDetails(verificationData)
    handleAddCardSelection()
  }

  const gateWayDetails = gw_data && gw_data.data ? getLiveGatewayDetails(gw_data.data) : null;


  return (
    <>
      {loadingPayment ||
        gw_loading ||
        onReplaceCardToken.isLoading ||
        onInitializeTransaction.isLoading ||
        ct_loading ||
        (onRemoveCardToken.isLoading && <Loader isFullPage={true} />)}
      <CardDetailsBox>
        {cardDetails && (
          <ul className="">
            <li>
              <div className="title">Card Number</div>
              <div className="details">
                **** **** ****{" "}
                {cardDetails.lastFourDigit || cardDetails?.last4Digit}
              </div>
            </li>
            {cardDetails?.gatewayCustomerName &&
              cardDetails?.gatewayCustomerName !== " " && (
                <li>
                  <div className="title">Holder’s Name</div>
                  <div className="details">
                    {cardDetails?.gatewayCustomerName}
                  </div>
                </li>
              )}
            <li>
              <div className="title">Valid Till</div>
              <div className="details">
                {cardDetails.expiryMonth}/{cardDetails.expiryYear.slice(-2)}
              </div>
            </li>
          </ul>
        )}

        {/* {!cardDetails ? ( */}
        <div
          className="addPaymentContainer mb-0"
          onClick={() => {
            if (cardDetails) {
              handleRemoveCard();
            } else {
              setShowCreateMethod(true)
              // handleAddCardSelection();
            }
          }}
        >
          {cardDetails ? (
            <div className="text remove d-inline-flex gap-1 align-items-center">
              <RemoveIcon />
              Remove card
            </div>
          ) : (
            <>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8125 5.875H8.375C8.02982 5.875 7.75 5.59518 7.75 5.25V1.8125C7.75 1.29473 7.33027 0.875 6.8125 0.875C6.29473 0.875 5.875 1.29473 5.875 1.8125V5.25C5.875 5.59518 5.59518 5.875 5.25 5.875H1.8125C1.29473 5.875 0.875 6.29473 0.875 6.8125C0.875 7.33027 1.29473 7.75 1.8125 7.75H5.25C5.59518 7.75 5.875 8.02982 5.875 8.375V11.8125C5.875 12.3303 6.29473 12.75 6.8125 12.75C7.33027 12.75 7.75 12.3303 7.75 11.8125V8.375C7.75 8.02982 8.02982 7.75 8.375 7.75H11.8125C12.3303 7.75 12.75 7.33027 12.75 6.8125C12.75 6.29473 12.3303 5.875 11.8125 5.875Z"
                  fill="#00AE97"
                />
              </svg>

              <div className="text">Add Payment Method</div>
            </>
          )}
        </div>
        {/* ) : (
          <></>
        )} */}
      </CardDetailsBox>

      {/* Add Payment Method */}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showCreateMethod}
        onClose={() => setShowCreateMethod(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "500px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "90%",
              margin: "auto",
            },
          },
        }}
      >
        <AddPaymentMethod
          getVerificationDetails={handleVerificationDetails}
          handleClose={() => setShowCreateMethod(false)}
        />
      </Dialog>

      {/* Confirm Add card */}
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openConfirmChange}
        onClose={() => setOpenConfirmChange(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "500px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "90%",
              margin: "auto",
            },
          },
        }}
      >
        <ConfirmChangeCard
          handleAdd={handleAddCardSelection}
          handleClose={() => setOpenConfirmChange(false)}
        />
      </Dialog>

      {/* Stripe Modal */}
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openStripe}
        onClose={() => setOpenStripe(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "600px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "95%",
              margin: "auto",
            },
          },
        }}
      >
        <LoadStripe
          userDetails={userDetails}
          gateWayDetails={gateWayDetails}
          handleClose={() => setOpenStripe(false)}
        // handleCreateCardToken={handleCreateCardToken}
        />
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openAddPaymentMethodSuccess}
        onClose={() => setOpenAddPaymentMethodSuccess(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "500px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "90%",
              margin: "auto",
            },
          },
        }}
      >
        <AddPaymentMethodSuccess />
      </Dialog>

      <Dialog
        // disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openPaystack}
        onClose={() => setOpenPaystack(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "550px",
            height: "550px",
            "@media (max-width: 767px)": {
              // width: "92%",
              margin: "auto",
            },
          },
        }}
      >
        <iframe
          id="paystackFrame"
          width="100%"
          height="100%"
          src={`https://checkout.paystack.com/${initializeResp?.authorizationCode}`}
          frameBorder="0"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Paystack frame"
        />
      </Dialog>
    </>
  );
};
