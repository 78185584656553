import { styled } from "@mui/material";
import { PCButton } from "../../../../../../components";

interface Props {
  handleClose: () => void;
  handleAdd: () => void;
}

export const ConfirmChangeCardBox = styled("div")(({ theme }) => ({
  padding: "2.5rem",
  textAlign: "center",
  background: "#fff",

  "& .title": {
    fontSize: "1.125rem",
    fontWeight: 600,
    color: "#150200",
  },
  "& .details": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#847F7F",
    margin: "1.5rem 0 2rem",
  },

  "& .btnContainer": {
    display: "flex",
    gap: "0 2rem",
    justifyContent: "center",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    padding: "2rem",

    "& .title": {
      fontSize: "1rem",
      fontFamily: "Raleway",
    },
    "& .details": {
      fontSize: "0.75rem",
      margin: "1rem 0 1.5rem",
    },

    "& .btnContainer": {
      gap: "0 1.2rem",

      "& button": {
        width: "48%"
      },
    },
  },
}));

export const ConfirmChangeCard: React.FC<Props> = ({
  handleClose,
  handleAdd,
}) => {
  return (
    <ConfirmChangeCardBox>
      <h4 className="title">Change Payment Method</h4>
      <p className="details">
        Are you sure you want to change this payment method The current payment
        method will be removed, you can always change anytime to your preferred
        payment method.{" "}
      </p>

      <div className="btnContainer">
        <PCButton text="Cancel" variant="PrimaryOutline" click={handleClose} />

        <PCButton text="Proceed" click={handleAdd} />
      </div>
    </ConfirmChangeCardBox>
  );
};
