import { Formik } from "formik"
import * as Yup from "yup";
import { PyButton, PySelect, PyInput, ToastMessage, TOASTER_TYPE } from "../../../../components"
import { useQuery, useMutation } from "@tanstack/react-query";
import {
    getAllBanks,
    verifyAccountNumber,
    initializeTransaction,
    verifyTransaction,
    createCartToken,
} from "../../../../services/api";
import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { TCredential } from "../../PaymentMethod";
import PaystackPop from '@paystack/inline-js'
import { useMsal } from "@azure/msal-react";

const paystackChekoutUrl = "https://checkout.paystack.com"

const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name is required, select a bank"),
    accountNumber: Yup.string().required("Account number is required"),
    // accountName: Yup.string().required("Account name is required"),
    cardType: Yup.string().required("Card type is required"),
    brand: Yup.string().required("Card brand is required, select card brand"),
    bin: Yup.string().required("Enter first 6 digits of your card").max(6, "Maximum 6 digits"),
});

interface IProps {
    credentials: TCredential
    refetchCardToken: () => void
    openMethodAccordion: () => void
}

export const AddPaymentMethod: React.FC<IProps> = ({
    credentials,
    refetchCardToken,
    openMethodAccordion
}) => {
    const { accounts } = useMsal();
    const [verifyData, setVerifyData] = React.useState<any>(null);
    const [cardVerificationDetails, setCardVerificationDetails] = React.useState<any>(null);
    const { data: bank_data } = useQuery({
        queryKey: ["AllBanks"],
        queryFn: () => getAllBanks({ country: "Nigeria", gateway: "PAYSTACK" })
    })
    const { data: verify_account_data, isLoading: verify_account_loading, refetch: verify_account_refetch } = useQuery({
        queryKey: ["VerifyAccountNumber"],
        queryFn: () => verifyAccountNumber({
            accountNumber: verifyData?.accountNumber,
            bankCode: verifyData?.bankCode
        }),
        enabled: !!verifyData?.accountNumber && !!verifyData?.bankCode
    })
    const onInitializeTransaction = useMutation(initializeTransaction, {
        onSuccess: (res: any, variables) => {
            console.log({ res })
            if (res?.status === 401 || res?.status === 400 || res?.status === 500) return
            localStorage.setItem("P_D", JSON.stringify(res.data));
            // setAccessCode(res.data?.authorizationCode)

            handlePaystackResumeTransaction(res.data?.authorizationCode)

            // window.location.assign(`${paystackCheckoutUrl}/${res.data?.authorizationCode}`)
            ToastMessage(TOASTER_TYPE.SUCCESS, "Redirecting you to payment gateway");
        },
        onError: (error: unknown) => {
            if (axios.isAxiosError(error)) {
                console.log(error);
                // ToastMessage(TOASTER_TYPE.ERROR, error?.response?.statusText);
                // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
            }
        },
    });
    const onVerifyTransaction = useMutation(verifyTransaction, {
        onSuccess: (res: any) => {
            console.log({ res })
            handleCreateCardToken(res.data)
        },
        onError: (error: unknown) => {
            if (axios.isAxiosError(error)) {
                console.log(error);
                // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
            }
        },
    });
    const onCreateCartToken = useMutation(createCartToken, {
        onSuccess: (res: any) => {
            if (res.status === 200 || res.status === 201) {
                // setCardDetails(res.data.data);
                openMethodAccordion()
                refetchCardToken()
                ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
            }
        },
        onError: (error: unknown) => {
            if (axios.isAxiosError(error)) {
                // console.log("card token error", error);
                ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
            }
        },
    });

    React.useEffect(() => {
        // Listen for a message from the Paystack checkout page
        const messageListener = (event: any) => {
            if (event.origin !== paystackChekoutUrl) return;
            if (event.data.event === "success") {
                console.log(event)
                // run verify payment afterwards 
                handleVerifyTransaction(event.data.data)
                // onVerifyFunction(event.data.data);
            }
        };

        window.addEventListener("message", messageListener, false);

        // Clean up
        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, []);

    React.useEffect(() => {
        if (verifyData) {
            verify_account_refetch();
        }
    }, [verifyData]);

    const allBanks: any[] = bank_data?.data ? bank_data?.data.map((bank: any) => ({
        ...bank,
        value: bank.code
    })) : [];

    const initialValues = {
        bankName: "",
        accountNumber: "",
        accountName: "",
        cardType: "",
        brand: "",
        bin: "",
        country: "Nigeria",
        verificationRequired: true,
    };

    const cardTypes = [{ name: "Debit", value: "debit" }, { name: "Credit", value: "credit" }]

    const cardBrands = [{ name: "Mastercard", value: "mastercard" }, { name: "VISA", value: "visa" }, { name: "Verve", value: "verve" }]

    const getBankCode = (bankName: string) => {
        if (!bankName) return;
        const selectedBank = allBanks.filter((bank: any) => {
            return bankName === bank.name;
        });
        return selectedBank[0].code;
    };

    const handleVerifyAccountNumber = async (
        bankName: string,
        accountNumber: string
    ) => {
        if (!bankName) {
            ToastMessage(TOASTER_TYPE.ERROR, "Select bank");
        }
        if (accountNumber.length < 10) return;

        const data = {
            accountNumber: accountNumber,
            bankCode: getBankCode(bankName),
        };

        setVerifyData(data);
    };

    const handleAddPaystackCard = () => {
        const body = {
            paymentItemUniqueReferenceNumber: uuidv4(),
            paymentUniqueReferenceNumber: uuidv4(),
            amount: 10000, // In kobo
            customerEmail: credentials.email,
            customerId: "null",
            paymentCurrency: "NGN",
            paymentMethod: "card",
        };

        onInitializeTransaction.mutate(body as any);
    };

    const handlePaystackResumeTransaction = async (accessCode: string) => {
        const paystack = new PaystackPop();

        try {
            const popupPaystack = await paystack.resumeTransaction(accessCode);
            console.log(popupPaystack)

        } catch (error) {
            handleError(error);
        }
    }

    const handleError = (error: any) => {
        console.error('Transaction error:', error);
    }

    const handleVerifyTransaction = async (paystackResponse: any) => {
        const initialTransactionDetails = JSON.parse(
            localStorage.getItem("P_D") as string
        );
        const body = {
            paymentUniqueReferenceNumber:
                initialTransactionDetails.paymentUniqueReferenceNumber,
            paymentItemUniqueReferenceNumber:
                initialTransactionDetails.paymentItemUniqueReferenceNumber,
            paymentMethodVerification: true,
            paymentGatewayReferenceNumber: paystackResponse.reference,
            customerUniqueReferenceNumber:
                initialTransactionDetails.customerUniqueReferenceNumber,
        };

        if (onVerifyTransaction.isLoading) return;

        onVerifyTransaction.mutate(body as any);
    };

    const handleCreateCardToken = (verificationData: any) => {
        const body = {
            merchantUniqueReferenceNumber: verificationData.customerUniqueReferenceNumber,
            customerUniqueReferenceNumber: verificationData.customerUniqueReferenceNumber,
            paymentGatewayReference: verificationData.paymentGatewayReferenceNumber,
            customerEmailAddress: credentials.email,
            walletUniqueReferenceNumber: accounts[0]?.idTokenClaims?.sub,
            newCustomer: true,
            verification: {
                ...cardVerificationDetails,
                verificationRequired: false,
                firstName: accounts[0]?.idTokenClaims?.given_name,
                lastName: accounts[0]?.idTokenClaims?.family_name
            }
        };

        onCreateCartToken.mutate(body as any);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                const data = { ...values, accountName: verify_account_data.data?.account_name }
                setCardVerificationDetails(data)
                handleAddPaystackCard()
            }}
        >
            {(
                {
                    isValid,
                    touched,
                    errors,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    values,
                }
            ) => (
                <form className="flex flex-col gap-4 w-full rounded-2xl md:border md:border-py_black-100 md:p-6 lg:p-12" onSubmit={handleSubmit}>

                    <PySelect
                        fullWidth
                        useValue={false}
                        hasObjects
                        label="Bank"
                        name="bankName"
                        id="bankName"
                        selectableObjects={allBanks}
                        value={values.bankName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.bankName && Boolean(errors.bankName)}
                    />

                    <div className="flex flex-col w-full gap-1">
                        <PyInput
                            fullWidth
                            type="text"
                            label="Account number"
                            placeholder="Enter your bank account number"
                            name="accountNumber"
                            value={values.accountNumber}
                            onChange={(e) => {
                                handleChange(e)
                                handleVerifyAccountNumber(values.bankName, e.target.value)
                            }}
                            onBlur={handleBlur}
                            error={touched.accountNumber && Boolean(errors.accountNumber)}
                        />

                        {verify_account_data && verify_account_data.data && values.accountNumber.length === 10 && (
                            <div className="text-sm text-py_green_2-600 font-medium">
                                {verify_account_data.data?.account_name}
                            </div>
                        )}
                    </div>


                    <PySelect
                        fullWidth
                        useValue
                        hasObjects
                        label="Card type"
                        name="cardType"
                        value={values.cardType}
                        selectableObjects={cardTypes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cardType && Boolean(errors.cardType)}
                    />

                    <PySelect
                        fullWidth
                        useValue
                        hasObjects
                        label="Card scheme"
                        name="brand"
                        value={values.brand}
                        selectableObjects={cardBrands}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.brand && Boolean(errors.brand)}
                    />

                    <PyInput
                        fullWidth
                        type="text"
                        label="BIN"
                        placeholder="Enter first 6 digits of your card"
                        name="bin"
                        value={values.bin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.bin && Boolean(errors.bin)}
                    />



                    <div className="flex gap-4 items-center mt-4">
                        <PyButton variant="SecondaryOutline" extraClass="!w-[104px]"> Cancel </PyButton>
                        <PyButton
                            type="submit"
                            extraClass="!w-[104px]"
                            isDisabled={onInitializeTransaction.isLoading}
                            loading={onInitializeTransaction.isLoading}
                        >
                            Add
                        </PyButton>
                    </div>
                </form>
            )}
        </Formik>
    )
}