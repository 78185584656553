import LoaderSVG from "../../assets/images/loader-img.png";
import * as React from "react";
import { styled } from "@mui/material";
import { keyframes } from "@mui/system";

interface Props {
  isFullPage: boolean;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderBox = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 20,
  height: "100vh",
  overflow: 'hidden',

  "& img": {
    animation: `${spin} 1s infinite ease`,
  },
}));

export const Loader: React.FC<Props> = ({ isFullPage = true }) => {
  const backgroundBlur = {
    backgroundColor: isFullPage ? "rgba(200,200,200,0.25)" : "unset",
  };

  return (
    <LoaderBox
      className="w-100 d-flex justify-content-center align-items-center"
      style={backgroundBlur}
    >
      <img
        src={LoaderSVG}
        alt="loading..."
        className="img-fluid"
        width={"50px"}
        height={"50px"}
      />
    </LoaderBox>
  );
};
