import { FormControl, styled } from "@mui/material";

export const SelectInputBox = styled(FormControl)(({ theme }) => ({
  width: "100%",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    paddingLeft: "0 !important",
  },

  "& .feedback": {
    color: "#F44336",

    "& span": {
      fontSize: "0.85rem",
    },
  },

  "& .select__input": {
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary,
      borderWidth: 1,
    },

    "& .MuiOutlinedInput": {
      paddingLeft: "0 !important",
    },

    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0.8rem 1rem",
      color: theme.palette.text,
    },

    "& .MuiSelect-iconOutlined": {
      color: theme.palette.primary.main,
    },

    "& legend": {
      // display: "none",
    },

    '& .MuiPaper-root':{
      maxHeight: "400px !important",
      overflowY: "auto",
    },

    // "& .MuiMenu-list": {
    //   maxHeight: "400px !important",
    //   overflowY: "auto",
    // },
  },
}));
