import * as React from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { LoginSuccessResponse } from "./LoginSuccessResponse";
import { getSessionByUrn } from "../../services/api";
import { useQuery } from "@tanstack/react-query";


const LoginComplete = () => {
  const { inProgress } = useMsal();
  const { pathname } = useLocation();
  const { data } = useQuery({
    queryKey: ["GetSessionUrn"],
    queryFn: getSessionByUrn,
  })

  React.useEffect(() => {
    if (pathname === "/logout") {
      const notifyAndClose = () => {
        // localStorage.removeItem("session_data");
        notifyMerchantOfCheckoutStatus();
        window.close();
      };

      // Notify and close on unmount
      return () => {
        notifyAndClose();
      };
    }
  }, []);

  React.useEffect(() => {
    if (pathname === "/logout") {
      if (inProgress === InteractionStatus.Startup) {
        localStorage.removeItem("session_data");

        return;
      }
    }
  }, [pathname]);

  const notifyMerchantOfCheckoutStatus = () => {
    // check is checkout is succeessful
    console.log(window.parent)
    const checkoutStatus = localStorage.getItem("c_status");
    if (checkoutStatus === "true") {
      window.opener.postMessage("window_success", "*");
    } else {
      window.opener.postMessage("window_cancel", "*");
    }
  };

  const handleSuccessCheckout = () => {

  }

  console.log({ data })

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <LoginSuccessResponse />
      </div>
    </>
  );
};

export default LoginComplete;
