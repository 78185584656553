import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config";
import { LoginSuccessResponse } from "./LoginSuccessResponse";
import { PaycurraBg } from "../../components";

const B2CLogin = () => {
  const { instance, accounts } = useMsal();

  React.useEffect(() => {
    handleLoginRedirect();
  }, []);

  const handleLoginRedirect = async () => {
    await instance
      .handleRedirectPromise()
      .then((res) => {
        const account = instance.getActiveAccount();
        if (!account) {
          instance.loginRedirect(loginRequest as any);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <PaycurraBg>
      {accounts && accounts.length >= 1 && (
        <div style={{ overflow: "hidden" }}>
          <LoginSuccessResponse />
        </div>
      )}
    </PaycurraBg>
  );
};

export default B2CLogin;
