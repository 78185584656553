import * as React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { getSessionData, setSessionData } from "../../config";

interface Props {
    children?: React.ReactNode;
}

export const AppGuard: React.FC<Props> = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const { pathname, search } = useLocation()
    const navigate = useNavigate();

    React.useEffect(() => {
        if (pathname === "/" && !search) {
            navigate("/home")
        } else {
            getToken();
        }
    }, [instance, inProgress]);

    const getToken = async () => {
        await instance
            .handleRedirectPromise()
            .then(async () => {
                const account = instance.getActiveAccount();


                if (!account) {
                    navigate("/login")
                } else {
                    if (account && account?.idTokenClaims) {
                        const userData = {
                            conURN: account && account?.idTokenClaims.extension_UserURN,
                            wRUN: account?.idTokenClaims.sub,
                            name: `${account.idTokenClaims?.given_name} ${account.idTokenClaims?.family_name}`
                        }

                        const existingSessionData = getSessionData()
                        const updateData = existingSessionData
                            ? { ...existingSessionData, ...userData }
                            : userData

                        setSessionData(updateData)
                    }
                }
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    return accounts && accounts.length >= 1 ? (
        <React.Fragment>
            {children}
            <Outlet />
        </React.Fragment>
    ) : null;
}