import * as React from "react";
import { styled } from "@mui/material";
import PaycurraLogoImg from "../../assets/images/logo/paycurra-logo.svg";
import PaycurraLogoIdentityImg from "../../assets/images/logo/paycurra-logo-identity.svg";

// full - fot full logo
// id - for identity logo
type TLogoType = "full" | "id";

interface LogoProps {
  type?: TLogoType;
}

export const LogoBox = styled("div")(({ theme }) => ({}));

export const PaycurraLogo: React.FC<LogoProps> = ({ type = "full" }) => {
  return (
    <LogoBox>
      <img
        src={type === "full" ? PaycurraLogoImg : PaycurraLogoIdentityImg}
        alt="Paycurra logo"
      />
    </LogoBox>
  );
};
