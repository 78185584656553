import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../../config";
import { Link, useLocation } from "react-router-dom";
// import { InteractionStatus } from "@azure/msal-browser";
import { NavBox } from "./navBar.styles";
import PaycurraLogo from "../../../assets/logo/logo-paycurra.svg";
import { GlobeIcon, LogoutIcon, MenuIcon, ProfileIcon } from "../../../assets/svg";
import { decodeToken } from "../../../utils/helper";

export const NavBar = () => {
  const { instance, accounts, } = useMsal();
  const [userDetails, setUserDetails] = React.useState<any>(null);

  React.useEffect(() => {
    if (accounts && accounts.length >= 1) {
      acquireToken(accounts[0]);
    }
  }, [accounts]);

  const closeWindow = async () => {
    localStorage.clear();
    window.close()
    // const logoutRequest = {
    //   accounts: instance.getAccountByHomeId(accounts[0].homeAccountId),
    //   postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    // };

    // Handle msal loogout
    // instance.logoutRedirect(logoutRequest);
  };

  const acquireToken = async (account: any) => {
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account: account,
      } as any);

      if (response) {
        const decodedToken = decodeToken(response.idToken);
        setUserDetails(decodedToken);
      }
    } catch (err) { }
  };

  const isLoggedIn: boolean = accounts && accounts.length >= 1 ? true : false;

  return (
    <>
      <NavBox className="bg-white z-[5000]">
        <div className="container-fluid px-lg-4">
          <div className="d-flex justify-content-between align-items-center !py-5">
            <Link to="#" className="d-inline-flex flex-column logo-link">
              <img className="" src={PaycurraLogo} alt="Paycurra logo" />
              {isLoggedIn && <small className="ms-auto">Checkout</small>}
            </Link>

            <IconButton
              className="!inline-flex md:!hidden"
            >
              <MenuIcon />
            </IconButton>

            <div className="hidden md:flex md:items-center !gap-2">
              <Link to="#" className="me-2 underline">
                Contact Support
              </Link>

              {isLoggedIn && (
                <div className="greet">Hello, {userDetails?.given_name}</div>
              )}

              {isLoggedIn && (
                <div className="d-inline-flex gap-3 align-items-center">
                  <div className="profile-icon">
                    <ProfileIcon />
                  </div>

                  <GlobeIcon />

                  <IconButton onClick={closeWindow}>
                    <Tooltip title="Logout">
                      <LogoutIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </NavBox>
    </>
  );
};
