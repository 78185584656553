import React from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { getSessionData } from "../../config";
import { Loader, ToastMessage, TOASTER_TYPE } from "..";
import { StripeStylesBox } from "./stripe.styles";
import StripeLogo from "../../assets/images/stripe-logo.png";
import VisaLogo from "../../assets/images/visa.png";
import MasterCardLogo from "../../assets/images/mastercard-logo.png";

export const StripePaymentForm: React.FC<any> = ({
  handleCreateCardToken,
  handleClose,
  userDetails,
  stripePromise,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  console.log(userDetails, "stripe");

  const handleSubmit = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    // setPaymentLoading(true);

    const cardElement = elements.getElement(CardElement);

    if (cardElement) setIsLoading(false);

    if (!cardElement) {
      ToastMessage(TOASTER_TYPE.ERROR, "Card element not found");
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error.message);
    } else {
      const sessionData = getSessionData();
      handleClose();

      const data = {
        customerUniqueReferenceNumber: sessionData.tenant,
        paymentGatewayReferenceNumber: paymentMethod?.id,
        gatewayCustomerId: paymentMethod?.id,
        newCustomer: true,
      };

      // Handle successful payment method
      handleCreateCardToken(data);
    }
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js or elements have not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) setIsLoading(false);

    if (!cardElement) {
      ToastMessage(TOASTER_TYPE.ERROR, "Card element not found");
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (result.error) {
      // Handle error
    } else {
      // console.log("Payment method ID:", result.paymentMethod.id);
      const sessionData = getSessionData();
      handleClose();


      const data = {
        customerUniqueReferenceNumber: sessionData.tenant,
        paymentGatewayReferenceNumber: result.paymentMethod.id,
        gatewayCustomerId: null,
        newCustomer: true,
      };

      // Handle successful payment method
      handleCreateCardToken(data);

      // Continue with further processing or submit to your server
    }
  };

  return (
    // <form onSubmit={handleSubmit}>
    <StripeStylesBox>
      <div className="headerSection">
        <h3 className="title">Add Payment Method</h3>
      </div>
      <div className="subSection">
        Make sure the debit card belongs to you, by adding your card a
        processing of NGN 100.00 will be deducted from your account.
      </div>

      {/* <form onSubmit={handleSubmit}>
        <label className="mb-3">Card Details</label>
        <CardElement options={cardElementOptions} />

        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn py-2 btn-primary btn-sm "
            type="submit"
            disabled={!stripe}
          >
            Add card
          </button>
        </div>
      </form> */}

      <div className="bodySection">
        <div className="logoSection">
          <img
            src={StripeLogo}
            width={"100"}
            className="img-fluid"
            alt="stripe logo"
          />

          <div className="emailHolder">
            <div className="email"> {userDetails?.email}</div>
            <div className="amount">
              Pay <span>NGN 100</span>
            </div>
          </div>
        </div>

        <div className="creditSection">
          <div className="textHolder">Credit or Debit Card</div>
          <div className="imgHolder">
            <img src={VisaLogo} width={"50"} className="img-alt" />
            <img src={MasterCardLogo} width={"45"} className="img-alt" />
          </div>
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="Stripe__Label">Card Number</label>
            <CardNumberElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                  },
                  invalid: {
                    color: "#ff3860",
                  },
                },
              }}
            />
          </div>

          <div className="row mb-4">
            <div className="col-6">
              <label className="Stripe__Label">Expiration Date</label>
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                    },
                    invalid: {
                      color: "#ff3860",
                    },
                  },
                }}
              />
            </div>

            <div className="col-6">
              <label className="Stripe__Label">CVC / CVV</label>
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                    },
                    invalid: {
                      color: "#ff3860",
                    },
                  },
                }}
              />
            </div>
          </div>

          <div className="secureNote">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none">
              <g clipPath="url(#clip0_382_1450)">
                <path
                  d="M13.2812 17H3.71875C2.84042 17 2.125 16.2853 2.125 15.4062V7.96875C2.125 7.08971 2.84042 6.375 3.71875 6.375H13.2812C14.1596 6.375 14.875 7.08971 14.875 7.96875V15.4062C14.875 16.2853 14.1596 17 13.2812 17ZM3.71875 7.4375C3.42621 7.4375 3.1875 7.6755 3.1875 7.96875V15.4062C3.1875 15.6995 3.42621 15.9375 3.71875 15.9375H13.2812C13.5738 15.9375 13.8125 15.6995 13.8125 15.4062V7.96875C13.8125 7.6755 13.5738 7.4375 13.2812 7.4375H3.71875Z"
                  fill="#847F7F"
                />
                <path
                  d="M12.2188 7.4375C11.9255 7.4375 11.6875 7.1995 11.6875 6.90625V4.25C11.6875 2.49262 10.2574 1.0625 8.5 1.0625C6.74262 1.0625 5.3125 2.49262 5.3125 4.25V6.90625C5.3125 7.1995 5.0745 7.4375 4.78125 7.4375C4.488 7.4375 4.25 7.1995 4.25 6.90625V4.25C4.25 1.90612 6.15612 0 8.5 0C10.8439 0 12.75 1.90612 12.75 4.25V6.90625C12.75 7.1995 12.512 7.4375 12.2188 7.4375Z"
                  fill="#847F7F"
                />
                <path
                  d="M8.49992 12.0413C7.71863 12.0413 7.08325 11.406 7.08325 10.6247C7.08325 9.84338 7.71863 9.20801 8.49992 9.20801C9.28121 9.20801 9.91658 9.84338 9.91658 10.6247C9.91658 11.406 9.28121 12.0413 8.49992 12.0413ZM8.49992 10.2705C8.30513 10.2705 8.14575 10.4292 8.14575 10.6247C8.14575 10.8202 8.30513 10.9788 8.49992 10.9788C8.69471 10.9788 8.85409 10.8202 8.85409 10.6247C8.85409 10.4292 8.69471 10.2705 8.49992 10.2705Z"
                  fill="#847F7F"
                />
                <path
                  d="M8.5 14.1667C8.20675 14.1667 7.96875 13.9287 7.96875 13.6354V11.6875C7.96875 11.3942 8.20675 11.1562 8.5 11.1562C8.79325 11.1562 9.03125 11.3942 9.03125 11.6875V13.6354C9.03125 13.9287 8.79325 14.1667 8.5 14.1667Z"
                  fill="#847F7F"
                />
              </g>
              <defs>
                <clipPath id="clip0_382_1450">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>Your transaction is secured with SSL encryption</span>
          </div>

          <button className="Stripe__button" type="submit">
            Add debit card
          </button>
        </form>
      </div>

      {/* <CardElement />
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn btn-primary btn-sm"
          type="submit"
          disabled={!stripe}
        >
          Pay
        </button>
      </div>
    </form> */}
    </StripeStylesBox>
  );
};
