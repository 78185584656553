import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "../utils/navigationClient";

export const ClientSideNavigation: React.FC<any> = ({ pca, children }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = React.useState(true);
  React.useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
};
