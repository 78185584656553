import { Link } from "react-router-dom";
import Logo from "../../../../assets/logo/logo-paycurra.svg";
import {
  FbIcon,
  IgIcon,
  // PinterestIcon,
  XIcon
} from "../../../../assets/svg";
import { urlConfig } from "../../../../config";
import { HOME_URL } from "../../../../utils/routes.url";

export const Footer = () => {
  return (
    <footer className="bg-py_black-100">
      <div className="container py-14 px-5 md:px-10 xl:py-20 lg:px-16">
        <div className="flex !flex-col md:!flex-row xl:gap-[180px]">
          <div className="flex flex-col gap-y-14 !order-2 w-full pt-14 md:w-5/12 md:!order-1 md:pt-0 xl:w-5/12">
            <Link
              to={HOME_URL}
              className="inline-flex flex-col w-[80px] relative z-[1000] md:w-[103px] lg:w-[110px] xl:w-[130px]">
              <img
                src={Logo}
                width={130}
                height={42}
                alt="Paycurra logo"
                className="w-[80px] md:w-[103px] lg:w-[110px] xl:w-[130px]"
              />
              <small className="ms-auto text-py_black-950 text-[0.65rem] font-cabin mt-[-0.5rem] lg:text-[0.875rem]">
                Checkout
              </small>
            </Link>

            {/* <div className="">
              <div className="text-py_black-950 font-semibold mb-2">
                Download app:
              </div>
              <div className="inline-flex gap-2 flex-wrap flex-col xl:flex-row">
                <Image
                  src={GooglePlay}
                  alt="Google play button"
                  className="md:w-[150px] h-[48px] xl:w-[110px]"
                />
                <Image
                  src={AppleStore}
                  alt="Apple store button"
                  className="md:w-[150px] h-[48px] xl:w-[110px]"
                />
              </div>
            </div> */}
          </div>

          <div className="flex ml-auto flex-row flex-wrap !order-1 w-full md:w-7/12 md:!order-2 ml-0 xl:w-7/12">
            <ul className="inline-flex flex-col gap-y-3 text-py_black-500 w-1/2 mb-16 md:gap-y-4  xl:w-4/12 xl:mb-0">
              <li className="mb-2 text-[1rem] font-semibold">Products</li>
              <li className="tracking-[-0.32px] text-[0.875rem]">
                <a target="_blank" href={urlConfig.SITE_LINKS.merchant}>
                  Merchant
                </a>
              </li>
              <li className="tracking-[-0.32px] text-[0.875rem]">
                <a target="_blank" href={urlConfig.SITE_LINKS.checkout}>
                  Checkout
                </a>
              </li>
              <li className="tracking-[-0.32px] text-[0.875rem]">
                <a target="_blank" href={urlConfig.SITE_LINKS.marketplace}>
                  Marketplace
                </a>
              </li>
              {/* <li className="tracking-[-0.32px] text-[0.875rem]">
            For individuals
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem]">
            For businesses
            </li> */}
            </ul>

            <ul className="inline-flex flex-col gap-y-3 text-py_black-500 w-1/2 md:gap-y-4  xl:w-4/12">
              <li className="mb-2 text-[1rem] text-py_black-500 font-semibold">
                Company
              </li>
              <li className="tracking-[-0.32px] text-[0.875rem]">
                About Paycurra
              </li>
              <li className="tracking-[-0.32px] text-[0.875rem]">
                <a href={`${window.location.origin}/home#features`}>
                  Features
                </a>
              </li>
              {/* <li className="tracking-[-0.32px] text-[0.875rem]">
              Integration
            </li> */}
              <li className="tracking-[-0.32px] text-[0.875rem]">Policies</li>
              {/* <li className="tracking-[-0.32px] text-[0.875rem]">
              Careers
            </li> */}
            </ul>

            <ul className="inline-flex flex-col gap-y-3 text-py_black-500 w-1/2 md:gap-y-4  xl:w-4/12">
              <li className=" mb-2 text-[1rem] font-semibold">Support</li>
              <li className="tracking-[-0.32px] text-[0.875rem]">
                Contact sales
              </li>
              {/* <li className="tracking-[-0.32px] text-[0.875rem]">
              Help center
            </li> */}
              <li className="tracking-[-0.32px] text-[0.875rem]">
                Privacy policy
              </li>
              <li className="tracking-[-0.32px] text-[0.875rem]">Cookies</li>
              <li className="inline-flex gap-x-5 mt-10">
                <a href="https://www.instagram.com/paycurra/" target="_blank">
                  <IgIcon />
                </a>
                <a href="https://x.com/paycurra" target="_blank">
                  <XIcon />
                </a>
                <a href="https://web.facebook.com/paycurra" target="_blank">
                  <FbIcon />
                </a>
                {/* <Link href="/">
                <PinterestIcon />
              </Link> */}
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-3 text-py_black-300 text-[0.75rem] md:text-[0.875rem] font-semibold !mt-10">
          <p>
            © 2024 Paycurra. All rights reserved. PAYCURRA is a registered
            trademark of Paycurra Limited.
          </p>
          <p>
            Nigeria Operations, Paycurra Limited (RC7638341) Mulliner Towers, 39
            Alfred Rewane Road, Ikoyi, Lagos 101233
          </p>
          <p>
            UK operations, Paycurra Limited (Company No. 15551848) with
            registered address at 124 City Road, London, EC1V 2NX, United
            Kingdom.
          </p>
        </div>
      </div>
    </footer>
  );
};
