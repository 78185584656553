import { useMutation } from "@tanstack/react-query"
import { selectDefaultCardToken } from "../../../../services/api"
import { PyButton } from "../../../../components"
import { IconButton } from "@mui/material"
import { BackIcon } from "../../../../assets/svg"
import MasterCardLogo from "../../../../assets/images/mastercard-logo.png"
import VisaLogo from "../../../../assets/images/visa.svg"
import VerveLogo from "../../../../assets/images/verve.svg"

interface IProps {
    cardToken: any
    handleClose: () => void
}

export const ConfirmSelection: React.FC<IProps> = ({
    cardToken,
    handleClose
}) => {
    const onSelectDefaultCartToken = useMutation({
        mutationKey: ["SelectDefaultCard"],
        mutationFn: selectDefaultCardToken,
        onSuccess: () => {
            setTimeout(() => {
                handleReturnToMarketplace()
            }, 500)
        },
        onError: () => {
        }
    })

    const handleSelectDefaultCard = (data: any, id: number) => {
        onSelectDefaultCartToken.mutate({
            uniqueReferenceNumber: data?.uniqueReferenceNumber,
            walletUniqueReferenceNumber: data?.walletUniqueReferenceNumber,
            merchantUniqueReferenceNumber: data?.customerUniqueReferenceNumber,
            consumerUniqueReferenceNumber: data?.consumerUniqueReferenceNumber
        } as any)
    }

    const handleReturnToMarketplace = () => {
        if (window.name === "PaymentMethodWindow") {
            window.opener.postMessage(
                { status: "success", data: cardToken },
                "*"
            );

            window.addEventListener("message", (event) => {
                if (event.data.status === "received") {
                    window.close();
                }
            });
            // Close window in case the above does not work 
            setTimeout(() => {
                window.close();
            }, 1000);
        } else {
            // console.log("Ref lost")
            window.close()
        }
    }

    return (
        <div className="flex flex-col gap-6 w-full">
            <div className="flex items-center !gap-4">
                <IconButton
                    className="!p-1"
                >
                    <BackIcon />
                </IconButton>
                <h3 className="text-xl text-py_black-950 font-semibold tracking-[-2%] leading-[1.5rem]">
                    Confirm selected payment method
                </h3>
            </div>
            <p className="text-base text-py_black-600 font-normal tracking-[-2%] leading-[22.5px]">
                You’ve selected this payment method
            </p>

            <div className="text-sm !px-3 !py-2 rounded-lg border border-py_black-100 flex items-center justify-between md:!p-4">
                <h4 className="text-py_black-400 font-medium leading-[19.6px]">
                    Debit/ Credit Card
                </h4>

                <div className="inline-flex items-center !gap-1">
                    {cardToken.cardType.includes("visa") && (
                        <img
                            src={VisaLogo}
                            className="w-[26px] h-[16px] object-cover"
                        />
                    )}
                    {cardToken.cardType.includes("verve") && (
                        <img
                            src={VerveLogo}
                            className="w-[46px] h-[16px] object-cover"
                        />
                    )}
                    {cardToken.cardType.includes("mastercard") && (
                        <img
                            src={MasterCardLogo}
                            className="w-[26px] h-[16px] object-cover"
                        />
                    )}
                    <span className="text-py_black-950 font-normal leading-[16.8px]">
                        ***{cardToken?.lastFourDigit}
                    </span>
                </div>
            </div>

            <div className="flex items-center !gap-5">
                <PyButton
                    extraClass="!text-sm"
                    variant='SecondaryOutline'
                    click={handleClose}
                >
                    Change
                </PyButton>
                <PyButton
                    extraClass="!text-sm"
                    loading={onSelectDefaultCartToken.isLoading}
                    isDisabled={onSelectDefaultCartToken.isLoading}
                    click={handleSelectDefaultCard}
                >
                    Return to marketplace
                </PyButton>
            </div>
        </div>
    )
}