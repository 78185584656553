import { styled } from "@mui/material";
import CheckedImg from "../../../../../../assets/images/checked-1.svg";

export const AddPaymentMethodSuccessBox = styled("div")(({ theme }) => ({
  padding: "3.5rem 1.5rem",
  textAlign: "center",
  background: "#fff",
  fontWeight: 400,

  "& .title": {
    fontsize: "1.5rem",
    color: "#150200",
    margin: "2rem 0 0.875rem",
  },

  "& .details": {
    fontsize: "1rem",
    color: "#847F7F",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    padding: "2.5rem 1rem",

    "& .title": {
      fontsize: "1.125rem",
    },
    "& .details": {
      fontsize: "0.8rem",
      margin: "1rem 0 1.75rem",
    },

    "& .btnContainer": {
      gap: "0 1.2rem",
    },
  },
}));

export const AddPaymentMethodSuccess = () => {
  return (
    <AddPaymentMethodSuccessBox>
      <img src={CheckedImg} alt="payment success" className="img-fluid" />
      <h5 className="title">Successful!</h5>
      <p className="details">Payment method added successfully</p>
    </AddPaymentMethodSuccessBox>
  );
};
 