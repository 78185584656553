import { PaymentSuccessBox } from "./PaymentSuccess.style";
// import { PaycurraLogo, PCButton } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
// import { msalConfig } from "../../config";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { convertPriceToNaira } from "../../utils/helper";
import Lottie from "lottie-react";
import SuccessLottie from "../../assets/lottie/97240 success.json";
import { PyButton } from "../../components";
import { getSessionData } from "../../config";

export const PaymentSuccess = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  // const { instance, accounts } = useMsal();
  const [loading, setLoading] = React.useState<boolean>(false);

  const closeWindow = async () => {
    setTimeout(() => {
      setLoading(true);
    }, 2000)
    // localStorage.removeItem('session_data');
    localStorage.removeItem("ID_T");
    navigate('/logout')
    // const logoutRequest = {
    //   accounts: instance.getAccountByHomeId(accounts[0].homeAccountId),
    //   postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    // }; 

    // Handle msal loogout
    // instance.logoutRedirect(logoutRequest).then((res) => {
    //   console.log("Log out complete", res);
    // });
  };

  const removeDash = (text: string) => {
    if (!text) return "";
    return text.split("-").join("");
  };

  const totalAmountPaid = () => {
    const cardAmount = (state && state?.paymentDetails?.cardTokenChargedResult?.amount) || 0
    const rewardAmount = (state && state?.paymentDetails?.rewardRedemptionResult?.discountApplied) || 0
    const pointAmount = (state && state?.paymentDetails?.pointsRedemptionResult?.amount) || 0

    return cardAmount + rewardAmount + pointAmount
  }

  // console.log(state);

  const checkoutDetails = state && state?.paymentDetails?.data?.cardTokenChargedResult;
  const gateWayDetails = state && state?.gateWayDetails;

  return (
    <section className="flex justify-center items-center h-screen">
      <div className="col-xl-5 col-md-8">
        <PaymentSuccessBox>
          <div className="paymentContainer">
            <Lottie
              className="mx-auto d-block !mb-10 w-[100px] h-[100px] md:w-[200px] md:h-[200px]"
              animationData={SuccessLottie}
              loop={true}
            />

            <div className="headerContainer">
              <h3 className="title">Payment successful</h3>
              <div className="details">
                Your payment has been processed details of your transaction are
                included below
              </div>

              <div className="detailId">
                Transaction ID&nbsp;:&nbsp;
                {removeDash(
                  state?.paymentDetails?.sessionUniqueReferenceNumber
                ).substring(0, 12)}
              </div>
            </div>

            {/* <hr /> */}

            <ul className="transactDetailsGroup">
              <li className="detailsList">
                <div className="name">Total amount </div>
                <div className="value totalAmount">
                  <NumericFormat
                    value={convertPriceToNaira(totalAmountPaid())}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={gateWayDetails?.currencySymbol}
                  />
                </div>
              </li>

              <li className="detailsList">
                <div className="name">Paid by</div>
                {getSessionData() && (
                  <div className="value text-uppercase">
                    {getSessionData().name}
                  </div>
                )}
              </li>
              <li className="detailsList">
                <div className="name">Transaction Date </div>
                <div className="value">
                  {checkoutDetails?.transactionDate ? (
                    <>
                      {format(
                        parseISO(checkoutDetails?.transactionDate),
                        "dd MMM yyyy; hh:mm aa"
                      )}
                    </>
                  ) : (
                    <>{format(new Date(), "dd MMM yyyy; hh:mm aa")}</>
                  )}
                </div>
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-center">
            <PyButton loading={loading} click={closeWindow}>
              Return to Marketplace
            </PyButton>
          </div>

          <div className=""></div>
        </PaymentSuccessBox>
      </div>
    </section>
  );
};
