import { FormControl, styled } from "@mui/material";

export const InputBox = styled(FormControl)(({ theme }) => ({
  display: "flex",
  height: "55px",
  margin: "0.375rem 0",

  "& .input": {
    "& .MuiOutlinedInput-input": {
      padding: "1rem 1.5rem",
    },

    "& .MuiInputBase-root": {
      borderColor: "#C4C4C4",
      borderRadius: "5px",
    },

    "& .MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline": {
      padding: "0.5rem",
    },

    "& .MuiInputLabel-outlined": {
      fontSize: "0.865rem",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.text,
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 83, 73, 0.87)",
      borderWidth: 1,
    },

    "& .PrivateNotchedOutline-root-253.MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 83, 73, 0.87)",
      borderWidth: 1,
    },

    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      //   borderColor: "#f44336",
    },
  },
}));
