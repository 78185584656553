import * as React from "react";
import * as Yup from "yup";
import {
  PyButton,
  PyInput,
  PySelect,
  TOASTER_TYPE,
  ToastMessage
} from "../../../components";
import { Formik } from "formik";
// import Logo from "@/assets/logo/paycurra.svg";
import { useMutation } from "@tanstack/react-query";
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { urlConfig } from "../../../config";
import { bookADemo } from "../../../services/api";

const validationSchema = Yup.object().shape({
  companyEmail: Yup.string().email().required("Company email is required"),
  contactName: Yup.string().required("Full name is required"),
  companySize: Yup.string().required("Company size is required"),
  role: Yup.string().required("Company name is required"),
  termsOfUse: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  )
});

const companySizeOptions = [
  { name: "1 - 10", value: "1-10" },
  { name: "11 - 50", value: "11-50" },
  { name: "51 - 200", value: "51-200" },
  { name: "201 - 500", value: "201-500" },
  { name: "501 - 1000", value: "501-1000" },
  { name: "1001 - 10000", value: "1001-10000" },
  { name: "10001+", value: "10001+" }
];

const BookADemo = () => {
  const navigate = useNavigate();
  const [initialValues] = React.useState({
    companyEmail: "",
    contactName: "",
    companySize: "",
    role: "",
    comment: "",
    termsOfUse: false,
    privacyPolicy: false
  });

  const onBookingDemo = useMutation({
    mutationKey: ["bookADemo"],
    mutationFn: bookADemo,
    onSuccess() {
      handleSuccess();
    },
    onError(err: any) {
      console.log(err);
      if (err?.response.status == 409) {
        handleSuccess();
      } else {
        handleError(err);
      }
    }
  });

  const handleSuccess = () => {
    ToastMessage(
      TOASTER_TYPE.SUCCESS,
      "Your demo booking was successful and you will be contacted via your email."
    );
    navigate("/");
  };

  const handleError = (error: any) => {
    ToastMessage(
      TOASTER_TYPE.ERROR,
      "Failed to book your demo. Please try again or contact support"
    );
    console.log(error);
  };

  const handleJoinWaitingList = (values: any) => {
    console.log(values);

    const payload = { ...values };
    onBookingDemo.mutate({ ...payload });
  };

  return (
    <section className="w-full h-full">
      <div className="w-screen h-full min-h-screen bg-[white] flex">
        <div className="w-full h-full lg:w-[500px] 2xl:w-[1000px]">
          <div className="pt-[120px] py-[80px] w-full h-full flex flex-col items-center">
            {/* <div>
              <Link href={"/"}>
                <div className="w-[6rem] inline-flex flex-col 2xl:w-[180px] h-[35px] relative mb-6">
                  <Image src={Logo} fill alt="" className="w-full" />
                </div>
              </Link>
            </div> */}

            <div className="form-container w-full max-w-[300px] lg:max-w-[350px] 2xl:max-w-[500px]">
              <div className="mt-4 mb-10">
                <p className="text-py_black-950 text-[24px] font-bold mb-2">
                  Book a free demo with our experts
                </p>
                <p className="text-[12px] text-py_black-600 2xl:w-9/12">
                  Find out how our platform can help you build loyal customers
                  that are connected and engaged for accelerated business
                  growth.
                </p>
              </div>

              <div id="api" role="main">
                <section>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values: any) => {
                      handleJoinWaitingList(values);
                    }}>
                    {({
                      touched,
                      errors,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values: {
                        contactName,
                        role,
                        companyEmail,
                        comment,
                        companySize,
                        termsOfUse
                      }
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-4 mb-10">
                          <PyInput
                            fullWidth
                            label="Full name"
                            type="text"
                            name="contactName"
                            placeholder="Enter full name"
                            value={contactName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <PyInput
                            fullWidth
                            label="Company name"
                            type="text"
                            name="role"
                            placeholder="Enter company name"
                            value={role}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <PyInput
                            fullWidth
                            label="Work email"
                            type="text"
                            name="companyEmail"
                            placeholder="Enter work email"
                            value={companyEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <div className="flex items-start gap-5">
                            <div className="w-full lg:w-[calc(50%-10px)]">
                              <PyInput
                                fullWidth
                                label="Phone number"
                                type="text"
                                name="comment"
                                placeholder="Enter phone number"
                                value={comment}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="w-full lg:w-[calc(50%-10px)]">
                              <PySelect
                                hasObjects
                                useValue
                                label="Company size"
                                name="companySize"
                                value={companySize}
                                placeholder="Choose an option"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                selectableObjects={companySizeOptions}
                                error={
                                  touched.companySize &&
                                  Boolean(errors.companySize)
                                }
                              />
                            </div>
                          </div>

                          <div className="mt-6 flex flex-col gap-4">
                            <FormControl
                              required
                              error={
                                touched.termsOfUse && Boolean(errors.termsOfUse)
                              }
                              component="fieldset"
                              variant="standard">
                              <FormControlLabel
                                className="!text-py_black-600"
                                control={
                                  <Checkbox className="!pl-2 !pb-0 !pt-0" />
                                }
                                label={
                                  <span className="text-[11px]">
                                    I would like to receive emails from Paycurra
                                    about product updates, industry news, and
                                    events and I understand that I can
                                    unsubscribe at any time if I change my mind.
                                  </span>
                                }
                              />
                            </FormControl>

                            <FormControl
                              required
                              error={
                                touched.termsOfUse && Boolean(errors.termsOfUse)
                              }
                              component="fieldset"
                              variant="standard">
                              <FormControlLabel
                                className="!text-py_black-600"
                                control={
                                  <Checkbox
                                    className="!pl-2 !pb-0 !pt-0"
                                    onChange={(e: any) => {
                                      console.log(e.target.checked);
                                      setFieldValue(
                                        "termsOfUse",
                                        e.target.checked
                                      );
                                      setFieldValue(
                                        "privacyPolicy",
                                        e.target.checked
                                      );
                                    }}
                                    name={`termsOfUse`}
                                    checked={termsOfUse}
                                  />
                                }
                                label={
                                  <span className="text-[11px]">
                                    I confirm that I agree with Paycurra&apos;s
                                    <a
                                      className="font-semibold text-primary-500"
                                      href="#">
                                      {" "}
                                      Privacy Policy
                                    </a>{" "}
                                    &{" "}
                                    <a
                                      className="font-semibold text-primary-500"
                                      href="#">
                                      {" "}
                                      terms of use
                                    </a>{" "}
                                  </span>
                                }
                              />
                              <FormHelperText>
                                <>{touched?.termsOfUse && errors?.termsOfUse}</>
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                        <PyButton
                          extraClass="!px-8 !w-full !mt-6 lg:!mt-10"
                          type="submit"
                          isDisabled={onBookingDemo.isLoading}
                          loading={onBookingDemo.isLoading}>
                          Book now
                        </PyButton>
                      </form>
                    )}
                  </Formik>
                </section>
              </div>
            </div>

            {/* <div className="mt-4 mb-2">
              <a
                href="#"
                className="font-semibold text-[16px] text-[#696A71] underline">
                Contact Support
              </a>
            </div> */}
          </div>
        </div>
        <div className="w-full border-r border-solid border-[##E6E6E7] hidden lg:block lg:w-[calc(100%-500px)] 2xl:w-[calc(100%-1000px)] ">
          <div
            className="relative max-w-full h-full overflow-idden"
            id="side-container">
            <div className="w-full h-full absolute top-0 bottom-0">
              <svg
                viewBox="0 0 900 900"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M376.366 -337.147C553.66 -350.84 731.694 -288.807 860.032 -165.765C976.034 -54.5501 1026.48 112.278 1012.06 272.318C1000.08 405.306 857.117 472.108 792.062 588.728C704.32 746.018 737.721 987.666 572.711 1059.92C398.962 1136 190.747 1036.9 44.6054 916.025C-95.7885 799.905 -149.766 619.547 -177.42 439.489C-205.541 256.388 -217.596 60.0017 -110.031 -90.848C-0.359257 -244.653 187.997 -322.6 376.366 -337.147Z"
                  fill="#FFF2F1"
                />
              </svg>
            </div>
            <div className="side-content-container side-slider z-50 px-10 py-10 lg:px-20 lg:py-16 max-w-[800px] mx-auto">
              <Swiper
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: false
                }}
                pagination={{
                  clickable: true
                }}
                navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper">
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-prev"
                    // style={{ width: "333px" }}
                    role="group"
                    aria-label="1 / 3"
                    data-swiper-slide-index="0">
                    <div className="slide-title !mt-[70px]">
                      Attract, engage and retain.
                    </div>
                    <div className="slide-description">
                      Boost your customer engagement with promotional campaigns
                      and loyalty rewards.
                    </div>
                    <div className="slideImageClass">
                      <img
                        src={urlConfig.WAITING_LIST_ILLUSTRATION_1}
                        alt="illustration"
                        width="100%"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-next"
                    role="group"
                    aria-label="3/3"
                    data-swiper-slide-index="2">
                    <div className="slide-title !mt-[70px]">
                      A rewarding way to shop and pay.
                    </div>
                    <div className="slide-description">
                      Enable delightful ways for customers to pay with points,
                      coupons, gift cards and more.
                    </div>
                    <div className="slideImageClass">
                      <img
                        src={urlConfig.WAITING_LIST_ILLUSTRATION_2}
                        alt="illustration"
                        width="100%"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="swiper-wrapper"
                    style={
                      {
                        //   swiperCenteredOffsetBefore: 0,
                        //   swiperCenteredOffsetAfter: "0px",
                      }
                    }
                    id="swiper-wrapper-16c30ecb7fc1e2ed"
                    aria-live="off">
                    <div
                      className="swiper-slide swiper-slide-active"
                      role="group"
                      aria-label="2 / 3"
                      data-swiper-slide-index="1">
                      <div className="slide-title !mt-[70px]">
                        Maximise conversions and accelerate growth.
                      </div>
                      <div className="slide-description">
                        Surface personalised rewards near realtime during the
                        customer checkout flow.
                      </div>
                      <div className="slideImageClass">
                        <img
                          src={urlConfig.WAITING_LIST_ILLUSTRATION_3}
                          alt="illustration"
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookADemo;
