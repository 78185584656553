import * as React from "react";
import { Route, Routes } from "react-router-dom";
import WalletCheckout from "../pages/WalletCheckout/WalletCheckout";
// import { NavBar } from "../components";
import LoginComplete from "../pages/B2C-Login/LoginComplete";
import B2CLogin from "../pages/B2C-Login/B2c-login";
import { PaymentSuccess } from "../pages/PaymentSuccess";
import { LandingPage } from "../pages/Website/Home/Home";
import { Pricing } from "../pages/Website/Pricing/Pricing";
import { DeveloperTools } from "../pages/Website/DeveloperTools/DeveloperTools";
import { VerifyPayment } from "../pages/VerifyPayment";
import BookADemo from "../pages/Website/book-a-demo/BookADemo";
import { PaymentMethod } from "../pages/PaymentMethod";
import { AppBaseLayout, AppGuard } from "../components";

const MainROutes = () => {
  return (
    <React.Fragment>
      <Routes> 
        {/* Website URL */}
        <Route path="/home" element={<LandingPage />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="book-a-demo" element={<BookADemo />} />
        <Route path="developer-tools" element={<DeveloperTools />} />
        <Route path="*" element={"Page Not Found... "} />

        <Route path="/logout" element={<LoginComplete />} />
        <Route path="/login" element={<B2CLogin />} />

        {/* Protected routes */}
        <Route path="/" element={<AppGuard />}>
          <Route element={<AppBaseLayout />}>
            <Route path="/" element={<WalletCheckout />} />
            <Route path="payment-method" element={<PaymentMethod />} />
            <Route path="verify-payment" element={<VerifyPayment />} />
            <Route path="payment-success" element={<PaymentSuccess />} />
            <Route path="/loginComplete" element={<LoginComplete />} />
          </Route>
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default MainROutes;
