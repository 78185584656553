import { CircularProgress, Dialog, IconButton, Radio, RadioGroup } from "@mui/material"
import { BackIcon, BlackCaretDownIcon } from "../../assets/svg"
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import React from "react"
import { AddPaymentMethod } from "./components"
import { useSearchParams, useNavigate } from "react-router-dom"
import { EmptyState, Loader, PyButton } from "../../components"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getCardToken, selectDefaultCardToken } from "../../services/api"
import { useMsal } from "@azure/msal-react"
import MasterCardLogo from "../../assets/images/mastercard-logo.png"
import VisaLogo from "../../assets/images/visa.svg"
import VerveLogo from "../../assets/images/verve.svg"
import { ConfirmSelection } from "./components/ConfirmSelection"
import { setSessionData } from "../../config"

export type TCredential = {
    consumerUniqueReferenceNumber: string,
    customerUniqueReferenceNumber: string,
    email: string
}

export const PaymentMethod = () => {
    const navigate = useNavigate()
    const { accounts } = useMsal();
    const [searchParam] = useSearchParams()
    const getCredentials = searchParam.get("credential")
    const credentials: TCredential | null = getCredentials ? JSON.parse(getCredentials as string) : null
    const [loadingId, setLoadingId] = React.useState<number | null>(null);
    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
    const [selectedTokenUrn, setSelectedTokenUrn] = React.useState<string>("");
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const { data, isLoading, isFetched, refetch } = useQuery({
        queryKey: ["GetCardToken"],
        queryFn: () => getCardToken({
            cusUrn: credentials?.customerUniqueReferenceNumber,
            conUrn: credentials?.consumerUniqueReferenceNumber,
            wUrn: accounts[0].idTokenClaims?.sub,
            name: accounts[0].idTokenClaims?.family_name
        }),
        enabled: !!accounts && accounts.length >= 1,
        refetchOnWindowFocus: false
    })

    React.useLayoutEffect(() => {
        if (!credentials) { navigate("/home") }
        else {
            const existingSessionData = getSelection()
            setSessionData({
                ...existingSessionData,
                tenant: credentials.customerUniqueReferenceNumber
            })
        }
    }, [])

    React.useEffect(() => {
        if (!data && accounts && accounts.length >= 1) {
            refetch()
        }
    }, [accounts])

    const handleBack = () => {
        window.close()
    }

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleToggleConfirm = () => setShowConfirm(!showConfirm)

    const cardTokens = data && data?.data?.data ? data?.data?.data : []

    return (
        <section className="w-full min-h-[80vh] bg-white py-10 lg:py-16">
            {isLoading && <Loader isFullPage />}
            <div className="container flex flex-col gap-6 h-full w-full">
                <IconButton
                    onClick={handleBack}
                    className="!inline-flex !w-fit !items-center !gap-2 !p-1 !rounded-lg !text-sm text-py_black-950 font-normal lg:!text-base"
                >
                    <BackIcon />
                    Back
                </IconButton>


                <div className="!rounded-2xl md:border md:border-py_black-100 md:p-10 xl:p-20">
                    <h3 className="text-lg text-py_black-950 font-semibold mb-6 md:text-xl lg:mb-8 lg:text-2xl">
                        Payment method
                    </h3>


                    <section className="flex flex-col w-full gap-4">
                        <Accordion
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                            className="border !rounded-2xl !shadow-none !px-4 !py-5 md:!border-py_black-100 md:!p-10"
                        >
                            <div className="headerSection">
                                <AccordionSummary
                                    expandIcon={<BlackCaretDownIcon />}
                                    aria-controls="panel1a-content"
                                    sx={{
                                        "& .MuiAccordionSummary-content, .Mui-expanded": {
                                            margin: "0 !important",
                                        },

                                        "& .Mui-expanded": {
                                            marginBottom: "2.5rem !important",

                                            "@media (max-width: 576px)": {
                                                marginBottom: "2rem !important",
                                            }
                                        }
                                    }}
                                    id="panel1a-header"
                                    className="!px-0 !min-h-fit !my-0"
                                >
                                    <div className="headerContainer">
                                        <h3 className="text-base text-py_black-950 font-medium md:text-[21.6px]">
                                            Choose from existing payment methods
                                        </h3>
                                    </div>
                                </AccordionSummary>
                            </div>

                            <AccordionDetails className="!p-0">
                                {isLoading && (
                                    <div className="h-[100px] w-full flex items-center justify-center">
                                        <CircularProgress />
                                    </div>
                                )}

                                {isFetched && (
                                    <>
                                        {cardTokens.length < 1
                                            ? (
                                                <EmptyState
                                                    width="w-[150px] lg:w-[200px]"
                                                    height="h-[150px] lg:h-[200px]"
                                                    emptyText="You have no existing payment method here"
                                                />
                                            )
                                            : (
                                                <ul className="flex flex-col w-full overflow-y-auto max-h-[300px] md:max-h-[320px]">
                                                    <RadioGroup
                                                        className="flex flex-col w-full !gap-2"
                                                        name="cardToken"
                                                        onChange={({ target }) => setSelectedTokenUrn(target.value)}
                                                    >
                                                        {cardTokens.map((token: any, id: number) => (
                                                            <li
                                                                key={id}
                                                                className="!px-3 !py-2 rounded-lg border border-py_black-100 flex items-center md:!p-4"
                                                            >
                                                                <Radio
                                                                    className="!p-0 !pr-1 md:!p-1 md:!pr-2"
                                                                    value={token.uniqueReferenceNumber}
                                                                />
                                                                <div className="inline-flex !gap-4 items-center justify-between text-xs md:text-sm lg:w-[320px]">
                                                                    <h4 className="text-py_black-400 font-medium leading-[19.6px]">
                                                                        Debit/ Credit Card
                                                                    </h4>

                                                                    <div className="inline-flex items-center !gap-1">
                                                                        {token.cardType.includes("visa") && (
                                                                            <img
                                                                                src={VisaLogo}
                                                                                className="w-[26px] h-[16px] object-cover"
                                                                            />
                                                                        )}
                                                                        {token.cardType.includes("verve") && (
                                                                            <img
                                                                                src={VerveLogo}
                                                                                className="w-[46px] h-[16px] object-cover"
                                                                            />
                                                                        )}
                                                                        {token.cardType.includes("mastercard") && (
                                                                            <img
                                                                                src={MasterCardLogo}
                                                                                className="w-[26px] h-[16px] object-cover"
                                                                            />
                                                                        )}
                                                                        <span className="text-py_black-950 font-normal leading-[16.8px]">
                                                                            ***{token?.lastFourDigit}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <PyButton
                                                                    click={handleToggleConfirm}
                                                                    // click={() => handleSelectDefaultCard(token, id)}
                                                                    isDisabled={loadingId === id || selectedTokenUrn !== token.uniqueReferenceNumber}
                                                                    loading={loadingId === id}
                                                                    variant={selectedTokenUrn === token.uniqueReferenceNumber ? "Primary" : "SecondaryOutline"}
                                                                    extraClass="!h-[1.85rem] !text-xs !px-3 !ml-auto md:!h-[2rem] md:!px-4 md:!text-sm"
                                                                >
                                                                    {selectedTokenUrn === token.uniqueReferenceNumber ? "Continue" : "Select"}
                                                                </PyButton>
                                                            </li>
                                                        ))}
                                                    </RadioGroup>

                                                </ul>
                                            )
                                        }
                                    </>
                                )}

                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                            className="border !rounded-2xl !shadow-none !px-4 !py-5 md:!border-py_black-100 md:!p-10 before:!content-none"

                        >
                            <div className="headerSection">
                                <AccordionSummary
                                    expandIcon={<BlackCaretDownIcon />}
                                    aria-controls="panel1a-content"
                                    sx={{
                                        "& .MuiAccordionSummary-content, .Mui-expanded": {
                                            margin: "0 !important",
                                        },

                                        "& .Mui-expanded": {
                                            marginBottom: "2.5rem !important",

                                            "@media (max-width: 576px)": {
                                                marginBottom: "2rem !important",
                                            }
                                        }
                                    }}
                                    id="panel1a-header"
                                    className="!px-0 !min-h-fit !my-0"
                                >
                                    <div className="headerContainer">
                                        <h3 className="text-base text-py_black-950 font-medium md:text-[21.6px]">
                                            Add payment method
                                        </h3>
                                    </div>
                                </AccordionSummary>
                            </div>

                            <AccordionDetails className="!p-0">
                                <AddPaymentMethod
                                    refetchCardToken={refetch}
                                    openMethodAccordion={() => setExpanded('panel1')}
                                    credentials={credentials as TCredential}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </section>
                </div>

            </div>

            <Dialog
                open={showConfirm}
                onClose={handleToggleConfirm}
            >
                <ConfirmSelection
                    handleClose={handleToggleConfirm}
                    cardToken={cardTokens.find((item: any) => item.uniqueReferenceNumber === selectedTokenUrn)}
                />
            </Dialog>
        </section>
    )
}