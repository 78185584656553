import * as React from "react";
import { WalletCheckoutBox } from "./WalletCheckout.style";
import {
  CardFrame,
  Loader,
  TOASTER_TYPE,
  ToastMessage,
} from "../../components";
import {
  PointCard,
  RadioCard,
  CardDetails,
  CartCard,
  OrderSummary,
  PaymentSuccess,
  EarnedPoint,
  TransparentAccordion,
  EnrollForLoyalty,
} from "./components";
import { Dialog, IconButton } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../config";
import { rewardRuleAttributes } from "../../models";
import {
  decodeToken,
  convertPriceToNaira,
  calculatedDiscountedAmountOnQtyAndPrice,
} from "../../utils/helper";
import {
  initializeOpenSession,
  updateOpenSession,
  getAllRewards,
  getEarnableRewards,
  getPoints,
  getMerchantDetails,
} from "../../services/api";
import {
  applyRewardToCartItems,
  applyRewardToTotalCart,
  getCartTotal,
} from "../../services/applyReward.service";
import { NumericFormat } from "react-number-format";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { PointIcon, RewardIcon, CardIcon, BackIcon } from "../../assets/svg";
import { setUpHeaders } from "../../config";
import { ICartItem, ICartWithReward } from "../../types/interfaces";
import { RewardRuleEffects } from "../../types";

const WalletCheckout: React.FC<any> = () => {
  const [isEnrollmentDone, setIsEnrollmentDone] =
    React.useState<boolean>(false);
  const [pointCheck, setPointCheck] = React.useState<boolean>(false);
  const [pointValue, setPointValue] = React.useState<string>("");
  const [rewardCheck, setRewardCheck] = React.useState<boolean>(false);
  const [cardCheck, setCardCheck] = React.useState<boolean>(false);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] =
    React.useState<boolean>(false);
  const [tokenLoading, setTokenLoading] = React.useState<boolean>(false);
  const [accessToken, setAccessToken] = React.useState<any>(null);
  const [rewardOnCartTotal, setRewardOnCartTotal] = React.useState<any>({
    percentOff: 0,
    price: 0,
  });
  const [rewardState, setRewardState] = React.useState<any>({
    data: null,
    selectedReward: null,
    loading: false,
  });
  const [cartItems, setCartItems] = React.useState<any>(null);
  const [renderCartItems, setRenderCartItems] = React.useState<
    ICartItem[] | any[]
  >([]);
  const [userDetails, setUserDetails] = React.useState<any>(null);
  const [cardDetails, setCardDetails] = React.useState<any>(null);
  const [gateWayDetails, setGateWayDetails] = React.useState<any>(null);
  const [cartTotal, setCartTotal] = React.useState<number>(0);
  const { instance, accounts } = useMsal();
  let popupOpened: boolean = false;

  const onInitializeOpenSession = useMutation(initializeOpenSession, {
    onSuccess: (res: any, variables: any) => {
      if (res.status === 200 || res.status === 201) {
        // Clear checkout status when session is initialized
        localStorage.removeItem("c_status");

        reward_refetch(); // fetch reward
        point_refetch(); // fetch point
        en_reward_refetch(); // fetch enable rewards
      }
      if (res.response?.status === 409) {
        // Similar SessionURN but likely that cart Items has been updated
        onUpdateOpenSession.mutate({ ...variables });
        return;
      }
      // ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onUpdateOpenSession = useMutation({
    mutationKey: ["UpdateSession"],
    mutationFn: updateOpenSession,
  });

  const { data: merchant_data } = useQuery({
    queryKey: ["MerchantDetails"],
    queryFn: getMerchantDetails,
    retry: 0,
    staleTime: Infinity,
  });

  const {
    data: reward_data,
    isLoading: reward_loading,
    refetch: reward_refetch,
  } = useQuery<any>(["rewards"], getAllRewards, {
    enabled: onInitializeOpenSession.isSuccess,
    retry: 0,
    staleTime: Infinity,
  });

  const {
    data: point_data,
    isLoading: point_loading,
    refetch: point_refetch,
  } = useQuery<any>(["points"], getPoints, {
    enabled: onInitializeOpenSession.isSuccess,
    retry: 0,
    staleTime: Infinity,
  });

  const {
    data: en_reward_data,
    isLoading: en_reward_loading,
    refetch: en_reward_refetch,
  } = useQuery<any>(["enableRewards"], getEarnableRewards, {
    enabled: onInitializeOpenSession.isSuccess,
    retry: 0,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    if (accounts.length >= 1) {
      window.setTimeout(() => {
        acquireToken(accounts[0]);
        if (!cartItems) onGetSessionCart();
      }, 2000);
    }
  }, [accounts]);

  React.useEffect(() => {
    if (isEnrollmentDone) {
      handleOpenSession(userDetails);
    }
  }, [isEnrollmentDone]);

  React.useEffect(() => {
    const removeShippingRewards = (rewards: any[]) => {
      return rewards.filter((reward) => reward.rewardEffect !== RewardRuleEffects.FreeShipping)
    }

    if (
      en_reward_data &&
      en_reward_data?.data &&
      en_reward_data?.data?.data &&
      en_reward_data?.data?.data?.sessionRewardsApplicable
    ) {
      setRewardState((prevState: any) => ({
        ...prevState,
        data: removeShippingRewards(
          en_reward_data?.data?.data?.sessionRewardsApplicable
        ),
      }));
    }
  }, [en_reward_data]);

  const acquireToken = async (account: any) => {
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    setTokenLoading(true);

    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account: account,
      } as any);


      if (response) {
        setTokenLoading(false);
        setAccessToken(response);
        ToastMessage(TOASTER_TYPE.SUCCESS, "You are logged in successfully");
        setUpHeaders(response.idToken); //set up api header
        localStorage.setItem("ID_T", response.idToken);
        const decodedToken = decodeToken(response.idToken);

        // Retriving existing localStorage object
        const item = localStorage.getItem("session_data");
        let existingData: any = JSON.parse(item as string);
        existingData = {
          ...existingData,
          wURN: decodedToken.sub,
          conURN: decodedToken.extension_UserURN,
          name: decodedToken.name,
        };

        // Updating local storage with wallent and consumer URN
        localStorage.setItem("session_data", JSON.stringify(existingData));
        setUserDetails(decodedToken);
      }
    } catch (err) { }
  };

  // Open Session
  const handleOpenSession = async (decoded_token: any) => {
    const payload = {
      wURN: decoded_token.sub,
      conURN: decoded_token.extension_UserURN,
      name: decoded_token.name,
    };

    onInitializeOpenSession.mutate(payload as any);
  };

  // Helper functions
  const onGetSessionCart = async () => {
    const sessionCart = JSON.parse(
      localStorage.getItem("session_data") as string
    )?.cart;

    const cartData: ICartWithReward[] = sessionCart?.map((item: any) => {
      return {
        ...item,
        sku: item.sku,
        price: item.price,
        quantity: item.quantity,
        newPrice: item.price,
        newQuantity: item.quantity,
        name: item.name,
        image: item.image,
        rewardType: "",
        discountPercent: null,
      };
    });

    setCartItems(cartData);
    setRenderCartItems(cartData);
  };

  // For Rewards
  const handleRewardCheck = () => {
    if (rewardCheck) {
      setRewardCheck(false);
      const rewards = rewardState.data.map((reward: any) => ({
        ...reward,
        selected: false,
      }));
      setRewardState((prevState: any) => ({
        ...prevState,
        data: rewards,
      }));
      setRenderCartItems(cartItems);
      setRewardOnCartTotal({ percentOff: 0, price: 0 });
    } else {
      setRewardCheck(true);
    }
  };

  const handleSelectReward = (selectedReward: any) => {
    setRewardCheck(true);
    const formattedData: any[] = rewardState.data.map(
      (reward: any, index: number) => {
        return {
          ...reward,
          selected:
            selectedReward.uniqueReferenceNumber ===
              reward.uniqueReferenceNumber
              ? true
              : false,
        };
      }
    );

    setRewardState((prevState: any) => ({
      ...prevState,
      data: formattedData,
    }));

    // Rewards that apply to cart items only
    const newCart = applyRewardToCartItems(selectedReward, cartItems);

    setRenderCartItems(newCart);

    let rewardDiscountedValue = newCart.filter(
      (cart: any) => cart.rewardType !== ""
    );

    // Rewards that apply to Cart total only
    const cartTotalReward = applyRewardToTotalCart(
      selectedReward,
      getCartTotal(cartItems)
    )
      ? applyRewardToTotalCart(selectedReward, getCartTotal(cartItems))
      : {
        percentOff: 0,
        price: 0,
      };

    const rewardAnddiscountInAmount =
      rewardDiscountedValue.length === 0
        ? { ...selectedReward, discountAmountApplied: cartTotalReward?.price }
        : {
          ...selectedReward,
          discountAmountApplied:
            (rewardDiscountedValue[0].price -
              rewardDiscountedValue[0].newPrice) *
            rewardDiscountedValue[0].quantity,
        };

    setRewardState((prevState: any) => ({
      ...prevState,
      data: formattedData,
      selectedReward: rewardAnddiscountInAmount,
    }));

    setRewardOnCartTotal((prevState: any) => ({
      ...prevState,
      percentOff: cartTotalReward?.percentOff,
      price: cartTotalReward?.price,
    }));
  };

  // For Point
  const handlePointValue = (point: string) => {
    setPointValue(point);
  };

  // For Card Details
  const handleGetCardFromComponent = (data: any) => {
    // console.log("get card", data)
    setCardDetails(data.card);
    setGateWayDetails(data.gateway);
  };

  // For Order Summary
  // Updating Cart Total globally
  const handleSettingCartTotal = (updatedPrice: number) => {
    setCartTotal(updatedPrice);
  };

  const userPointDetail = point_data && point_data?.data?.data[point_data?.data?.data.length - 1];

  const earnableRewards = en_reward_data && en_reward_data?.data && en_reward_data?.data?.data;

  return (
    <>
      {isEnrollmentDone && (tokenLoading || reward_loading || point_loading || en_reward_loading || onInitializeOpenSession.isLoading || onUpdateOpenSession.isLoading) && (
        <Loader isFullPage={true} />
      )}

      {!tokenLoading && accessToken && isEnrollmentDone && (
        <WalletCheckoutBox>
          <div className="container">
            {/* <Nav name={userDetails?.name} /> */}

            <div className="flex !gap-5 flex-wrap lg:flex-nowrap">
              <div className="w-full flex flex-col !gap-5 !p-5 border-py_black-100 border-r border-l border-b md:p-10 lg:w-[calc(50%-10px)] lg:py-14 lg:px-10">
                {/* Heeader */}
                <div className="header">
                  <div className="inline-flex !gap-4 items-center">
                    <IconButton
                      onClick={() => window.close()}
                      className="!pr-[0.5rem] !py-[0.25rem] !pl-0"
                    >
                      <BackIcon />
                    </IconButton>

                    <h3 className="inline-flex gap-[0.25rem] mb-0 tracking-[-0.4px] text-[1.15rem] md:text-[1.25rem]">
                      <div className="">Merchant's name:</div>
                      <div className="font-semibold">
                        {gateWayDetails?.companyName}
                      </div>
                    </h3>
                  </div>

                  <p className="details">
                    <strong>Apply your rewards to save and earn more! </strong>
                    <br />
                    Save big on your next purchase, receive discounts. redeem
                    points, free gifts and many more.
                  </p>
                </div>

                {/* Point section */}
                <div>
                  <CardFrame
                    hasIcon={true}
                    icon={<PointIcon />}
                    title={"Points"}
                    // subtitle={
                    //   userPointDetail
                    //     ? `${
                    //         gateWayDetails?.companyName
                    //           ? gateWayDetails?.companyName
                    //           : ""
                    //       } Points ${pointValue}`
                    //     : "No loyalty membership enrolled to your account."
                    // }
                    handleCheck={() => {
                      setPointCheck(!pointCheck);
                    }}
                    checkState={pointCheck}
                  >
                    {userPointDetail && (
                      <>
                        <PointCard
                          isPointChecked={pointCheck}
                          handleChange={handlePointValue}
                          totalAmount={
                            renderCartItems ? getCartTotal(renderCartItems) : 0
                          }
                          updatedCartTotal={cartTotal}
                          userPointDetails={userPointDetail}
                          gateWayDetails={gateWayDetails}
                        />
                        <small className="info">
                          Your order total will be adjusted and any reward due
                          shown
                        </small>
                      </>
                    )}
                  </CardFrame>
                </div>

                {/* Reward section */}
                <div>
                  <CardFrame
                    hasIcon={true}
                    icon={<RewardIcon />}
                    title={"Rewards"}
                    subtitle={
                      (rewardState.data && rewardState.data.length >= 1)
                        ? "View and select from the list of available rewards(s)"
                        : "No rewards earned on your account yet."
                    }
                    handleCheck={handleRewardCheck}
                    checkState={rewardCheck}
                  >
                    {rewardState.data && (
                      <>
                        <div className="d-flex gap-3 flex-column">
                          {rewardState.data.map(
                            (reward: any, index: number) => {
                              return (
                                <RadioCard
                                  key={`${index}-${reward.createdAt}`}
                                  title={reward?.rewardName}
                                  details={reward?.rewardReason}
                                  endDate={""}
                                  isSelected={reward.selected}
                                  click={() => handleSelectReward(reward)}
                                />
                              );
                            }
                          )}
                        </div>

                        <small className="info">
                          Your order total will be adjusted and any reward due
                          shown
                        </small>
                      </>
                    )}
                  </CardFrame>
                </div>

                {/* Card Details */}
                {userDetails && (
                  <div>
                    <CardFrame
                      hasIcon={true}
                      icon={<CardIcon />}
                      title={"Card Details"}
                      subtitle={
                        cardDetails
                          ? "Use your credit/debit card to make payment"
                          : "No card available please add a payment method."
                      }
                      handleCheck={() => {
                        setCardCheck(!cardCheck);
                      }}
                      checkState={cardCheck}
                    >
                      <CardDetails
                        userDetails={userDetails}
                        getCardDetails={handleGetCardFromComponent}
                      />
                    </CardFrame>
                  </div>
                )}
              </div>

              <div className="w-full flex flex-col !gap-5 p-6 border-py_black-100 border-r border-l border-b md:!gap-10 md:p-10 lg:w-[calc(50%-10px)] lg:py-14 lg:px-10">
                {/* Cart section */}
                <div>
                  <TransparentAccordion
                    title={
                      <div className="inline-flex items-center">
                        Cart (
                        <div className="text-primary-500">
                          {renderCartItems.length}
                        </div>
                        )
                      </div>
                    }
                    subtitle={`${renderCartItems ? renderCartItems.length : 0
                      } items in cart`}
                    hasCheckbox={false}
                  >
                    <div className="d-flex flex-column gap-3">
                      {renderCartItems &&
                        renderCartItems.map((item: ICartItem) => (
                          <CartCard
                            key={item.sku}
                            cartItem={item}
                            cartItems={renderCartItems}
                            gateWayDetails={gateWayDetails}
                            updateCartItems={setRenderCartItems}
                            amendSession={onUpdateOpenSession}
                          />
                        ))}
                    </div>
                  </TransparentAccordion>
                </div>

                {/* Order Summary */}
                <div>
                  <TransparentAccordion
                    title={"Order Summary"}
                    subtitle={
                      renderCartItems ? (
                        <NumericFormat
                          value={convertPriceToNaira(
                            getCartTotal(renderCartItems)
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={gateWayDetails?.currencySymbol}
                        />
                      ) : (
                        `${gateWayDetails?.currencySymbol}0`
                      )
                    }
                    isOrderSummary={true}
                    hasCheckbox={false}
                  >
                    <>
                      <OrderSummary
                        totalCost={
                          renderCartItems ? getCartTotal(renderCartItems) : 0
                        }
                        subTotal={
                          renderCartItems ? (
                            <NumericFormat
                              value={convertPriceToNaira(
                                getCartTotal(renderCartItems)
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={gateWayDetails?.currencySymbol}
                            />
                          ) : (
                            `${gateWayDetails?.currencySymbol}0`
                          )
                        }
                        rawCartTotal={getCartTotal(renderCartItems)}
                        updatedCartTotal={handleSettingCartTotal}
                        isCardSelected={cardCheck}
                        pointData={{
                          check: pointCheck,
                          value: pointValue,
                          userPointDetail,
                        }}
                        rewardData={{
                          isRewardSelected: rewardCheck,
                          allRewards: en_reward_data?.data?.data?.sessionRewardsApplicable
                            ? en_reward_data?.data?.data?.sessionRewardsApplicable
                            : [],
                          selectedReward: rewardState.selectedReward,
                          onCartTotal: rewardOnCartTotal,
                        }}
                        cardDetails={cardDetails}
                        gateWayDetails={gateWayDetails}
                        cartItems={renderCartItems}
                        updateCartItems={setRenderCartItems}
                      />
                    </>
                  </TransparentAccordion>
                </div>

                {/* Earned Point */}
                {(earnableRewards && (earnableRewards?.cartItemRewardsEarnable.length > 0 || earnableRewards?.cartTotalRewardsEarnable.length > 0 )) && (
                  <div>
                    <TransparentAccordion
                      title={"Earnable purchase rewards"}
                      hasCheckbox={false}
                    >
                      <>
                        {(earnableRewards?.cartItemRewardsEarnable.length >= 1 ||
                          earnableRewards?.cartTotalRewardsEarnable.length >= 1) && (
                            <EarnedPoint
                              cartItemEarnable={earnableRewards?.cartItemRewardsEarnable}
                              cartTotalEarnable={earnableRewards?.cartTotalRewardsEarnable}
                            />
                          )}
                      </>
                    </TransparentAccordion>
                  </div>
                )}
              </div>
            </div>
          </div>
        </WalletCheckoutBox>
      )}

      {!isEnrollmentDone && merchant_data && userDetails && accessToken && (
        <EnrollForLoyalty
          userDetails={userDetails}
          setIsEnrollmentDone={setIsEnrollmentDone}
          merchantDetails={merchant_data}
        />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openPaymentSuccess}
        onClose={() => {
          setOpenPaymentSuccess(!openPaymentSuccess);
        }}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            // height: "500px",
            width: "550px",
            "@media (max-width: 767px)": {
              width: "92%",
              margin: "auto",
            },
          },
        }}
      >
        <PaymentSuccess />
      </Dialog>
    </>
  );
};

export default WalletCheckout;
